.content {
  padding: 56px 56px 32px 56px;
  min-height: 100vh;
  width: calc(100% - 280px);
  margin-left: 280px;
  position: relative;
  overflow: auto;
  /* border: 4px solid #27AE60; */
}

@media (min-width: 992px) and (max-width: 1280px) {
  .content {
    padding: 56px 32px 32px;
  }
}

/* Manage Team, No Athletes, No Coaches Styles */

.no-record-found {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: -moz-calc(100vh - 223px);
  height: -webkit-calc(100vh - 223px);
  height: calc(100vh - 223px);
}

.no-record-found>div {
  display: flex;
}

@media screen and (max-width: 1366px) {
  .no-record-found .no-record-img {
    max-width: 440px !important;
    margin: 24px 0 0;
  }
}

.no-record-found .no-record-img {
  max-width: 492px;
}

.no-record-found .message-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.no-record-found p {
  color: #010101;
  text-align: center;
  margin: 30px 0;
  max-width: 382px;
}


/* Help Section */
.help_container {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 100px;
  width: 100%;
  height: 100%;
  max-width: 592px;
  overflow-y: auto;
  padding: 56px 48px;
  background: #F7F7F7;
  box-shadow: 0px 25px 47px rgba(0, 0, 0, 0.3);
}

/* 1- Team Selector and Selected Team Name Style */

.selected-option,
.main-title {
  font-style: normal;
  font-size: 24px;
  line-height: 120%;
  color: #000000;
  margin: 0;
  cursor: pointer;
  text-align: start !important;
}

.main-decription {
  margin: 20px 0 0;
  color: #2A2A2A !important;
  font-size: 16px;
  line-height: 140%;
  text-align: start !important;
}

.invite-decription {
  margin: 20px 0 0;
  color: #2A2A2A !important;
  font-size: 16px;
  line-height: 140%;
  text-align: center !important;
}

.small-decription {
  color: #2A2A2A !important;
  margin: 0 0 0 12px;
  font-size: 13px;
  line-height: 130%;
  text-align: start !important;
}

.small-decription-disabled {
  color: rgba(1, 1, 1, 0.4) !important;
  margin: 0 0 0 12px;
  font-size: 13px;
  line-height: 130%;
  text-align: start !important;
}

.selected-option {
  margin-right: 1rem !important;
}

/* 2- Search Bar Styles */

span[aria-label="search"] svg {
  height: 18px;
  width: 18px;
  color: #01010140 !important;
}

.help-search span[aria-label="search"] svg {
  height: 18px;
  width: 18px;
  color: #010101 !important;
}

.help-search ::placeholder {
  color: #010101 !important;
}

.search-field {
  display: flex;
  width: 100%;
  height: 45px;
  position: relative;
}

.change-password-link {
  background: none;
}

.search-field input,
.change-password-link {
  font-size: 15px;
  line-height: 150%;
  color: #010101 !important;
}

input[type="checkbox"]:checked:before {
  border: #000000;
  background-color: #000000;
}

/* 3- Calendar Styles */

.date {
  color: rgba(1, 1, 1, 0.8);
  margin: 8px 0 0 0;
  font-size: 15px;
  line-height: 150%;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.short-names {
  text-align: center;
  font-size: 14px;
  line-height: 120%;
  color: rgba(1, 1, 1, 0.4);
  margin: 0px;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
}

.month-view {
  font-size: 17px;
  line-height: 120%;
  text-align: center;
  color: #010101;
  margin: 0px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.calendar-table {
  margin: 0 auto;
  width: 312px !important;
  border-collapse: collapse;
  border-spacing: 0;
}

.calendar-table .today {
  background-color: #010101;
  border-radius: 25px;
  color: #fff !important;
}

.calendar-table th {
  border: 1px solid #01010140;
  color: #01010140 !important;
  border-width: 0 0 1px;
  font-size: 11.2244px !important;
  line-height: 150% !important;
  font-weight: normal !important;
}

.calendar-table td {
  border: none !important;
  border-width: 0;
  color: #010101 !important;
  font-size: 17px !important;
  line-height: 150% !important;
  font-weight: normal !important;
}

.calendar-table td,
.calendar-table th {
  padding: .55em 0.4em !important;
  height: 34px !important;
  width: 34px !important;
  text-align: center !important;
}

.calendar-table td:hover {
  background-color: #010101;
  border-radius: 25px;
  color: #fff !important;
  cursor: pointer !important;
}

.calendar-table .in-prev-month,
.calendar-table .in-next-month {
  opacity: 0.4;
}

/* 4- Help Card Styles */

/* 5- Three Dots Styles */

.dots-listing {
  position: absolute;
  right: 0;
  border-radius: 10px;
  z-index: 10;
  width: 120px !important;
  margin: 32px 6px 0 0 !important;
  padding: 12px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 1px 2px 8px #01010160;
}

.dot-item-names {
  color: #01010180 !important;
  cursor: pointer !important;
  font-size: 16px;
  line-height: 120%;
  padding: 12px 0 0;
}

.dot-item-names.active,
.dot-item-names:hover {
  color: #010101 !important;
}

/* Table Styles */

table {
  box-sizing: border-box !important;
  border-spacing: 0 1rem !important;
}

.athlete_list_item {
  z-index: 1;
  border-radius: 10px;
  cursor: pointer;
  padding: 0 12px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.10);
  -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.10);
  -moz-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.10);
  -webkit-transition: box-shadow 300ms ease-in-out;
  -moz-transition: box-shadow 300ms ease-in-out;
  -o-transition: box-shadow 300ms ease-in-out;
  transition: box-shadow 300ms ease-in-out;
  /* height: 90px; */
  min-height: 90px;
  background: #ffffff;
}

.athlete-name,
.coach-name {
  text-transform: capitalize;
}


.athlete-name,
.training_plan_name,
.sport_type,
.created_date,
.reviewed_status,
.draft_status,
.published_status,
.view_count,
.purchase_count,
.athlete-status,
.athlete-cycle-phase,
.athlete-cycle-length,
.athlete-readiness,
.athlete-next-training,
.athlete-email,
.athlete-member-since,
.athlete-checkins,
.coach-name,
.coach-email,
.coach-member-since,
.coach-status,
.sport_type,
.published_status,
.views_count,
.plan_purchases,
.plan_athletes_count,
.plan_video_link {
  font-size: 14px !important;
  line-height: 140%;
  text-align: left;
  width: 100%;
}

.athlete-cycle-length,
.athlete-readiness,
.athlete-next-training {
  margin-left: 12px;
}

.athlete-checkins {
  margin-left: 32px;
}

.video_link {
  font-size: 12px !important;
  line-height: 120%;
  color: #034cf4 !important;
  text-align: left !important;
}

.custom_tag_style {
  padding: 6px 8px;
  border-radius: 6px;
  width: auto;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  color: #2A2A2A;
  font-style: normal;
  font-weight: normal;
  list-style: none;
  margin: 8px 4px 0 0;
}

.custom_tag_style .tag-title {
  font-size: 12px;
  line-height: 120%;
}

.sport_type,
.created_date,
.reviewed_status,
.draft_status,
.video_link,
.published_status,
.view_count,
.purchase_count,
.athlete-cycle-phase,
.athlete-cycle-length,
.athlete-readiness,
.athlete-next-training,
.athlete-checkins,
.athlete-member-since,
.coach-member-since,
.sport_type,
.published_status,
.views_count,
.plan_purchases,
.plan_athletes_count {
  text-align: left !important;
}

.athlete-name,
.athlete-cycle-phase,
.athlete-readiness,
.athlete-member-since,
.coach-name,
.coach-member-since,
.athlete-checkins {
  color: #010101;
  text-align: left;
}

.athlete-email,
.coach-email {
  color: rgba(0, 0, 0, 0.66);
}

.athlete-status,
.athlete-cycle-length,
.athlete-next-training,
.coach-status {
  color: rgba(0, 0, 0, 0.66);
}






.tagInput {
  background-color: #ffffff !important;
  color: #151515 !important;
}

.ant-menu-item-selected {
  background-color: none !important;
}

.ant-menu-item-active {
  background-color: #010101 !important;
}

.ant-card-body {
  width: 100% !important;
  display: flex !important;
}

.ant-input-affix-wrapper.ant-input-password>.ant-input#password {
  background-color: #151515 !important;
  color: #FFFFFF;
}

.ant-input-affix-wrapper.ant-input-password>.ant-input#confirmPassword {
  background-color: #151515 !important;
  color: #FFFFFF;
}

.ant-btn:hover,
.ant-btn:active,
.ant-btn:focus {
  color: #000000;
}

.ant-modal-content {
  border-radius: 18px !important;
}


.active svg {
  color: #151515 !important;
}

/* Create Plans || Update Plans || Create Sessions || Update Sessions */
.fixed_header {
  height: 56px;
  position: fixed;
  top: 0px;
  left: 280px;
  width: calc(100% - 280px);
  background: #f7f7f7;
  z-index: 100;
  padding: 64px 0;
}

/* Settings Styles */

.settings-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 80px auto;
  width: 100%;
  max-width: 476px;
}

.update_password {
  margin: 0 auto !important;
}


.forgot-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  width: 100%;
  height: 100%;
  max-width: 425px;
}

.border-top {
  border-top: 0.35px solid rgba(1, 1, 1, 0.4);
}

.border-bottom {
  border-bottom: 0.35px solid rgba(1, 1, 1, 0.4);
}


.light_border_bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}

.border-bottom-red {
  border-bottom: 2px solid #EB5757;
}


.absolute_info {
  position: absolute;
  right: 19px;
  top: -8px;
  background: #8e8d8d;
  padding: 8px 12px;
  border-radius: 8px;
  max-width: 356px;
}

.absolute_info p {
  font-size: 12px;
  color: #ffffff;
}

.settings-form-item {
  width: 100%;
  margin: 0;
  padding: 12px 0;
  position: relative;
}

.settings-label {
  color: rgba(1, 1, 1, 0.4) !important;
  font-style: normal;
  font-size: 13px !important;
  line-height: 130% !important;
}

.settings-value {
  background: none;
  border: none;
  padding: 0;
  box-shadow: none;
  outline: none;
  margin: 0;
  font-family: inherit !important;
}

.settings-value,
.settings-form-item select,
.settings-value textarea {
  margin: 16px 0 0;
}

.settings-main input,
.settings-main input[type="password"],
.settings-main select,
.settings-main textarea {
  background: none !important;
  color: #010101;
  box-shadow: none;
  border: none;
  outline: none;
  padding: 0;
  font-size: 16.5px;
  line-height: 140%;
  font-style: normal;
  font-weight: normal;
}

.forget_password_input,
.forget_password_input:focus-visible {
  background: none !important;
  width: 100% !important;
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  outline: none !important;
  padding: 16px 8px;
  font-size: 16.5px;
  line-height: 140%;
  color: #010101 !important;
  font-style: normal;
  font-weight: normal;
}

.coach-types {
  border-radius: 5px;
  background: none !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
}



.disabledDiv {
  cursor: not-allowed !important;
  opacity: 0.4;
}

.disabledClick {
  pointer-events: none !important;
}

.hovering-card {
  background-color: rgb(255, 255, 255);
  min-width: 275px;
  min-height: 112px;
  height: auto;
  box-shadow: rgb(0 0 0 / 35%) 0px 8px 18px 0px;
  position: absolute;
  right: 0;
  margin-top: 24px;
  padding: 16px;
  border-radius: 12px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.hovering-card h3 {
  line-height: 28px;
  font-size: 18px;
  margin: 0 !important;
  font-weight: 900;
  color: #151515;
}

.hovering-card p {
  line-height: 20.24px;
  font-size: 16px;
  margin: 8px 0 0 !important;
  color: #151515;
}

.ant-select-selector {
  border: none !important;
  padding: 0px !important;
  margin-top: 1rem;
  line-height: 20.24px;
  font-size: 16px;
  font-weight: 900;
  color: #FFFFFF;
  text-transform: capitalize;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: none !important;
  margin: 0px !important;
  cursor: context-menu !important;
}

.btn-signup,
.sign-link {
  color: #010101 !important;
  font-size: 15px;
  line-height: 150%;
  margin: 0;
  padding: 4px 16px;
}

.btn-back {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background-color: #010101;
  color: #FFFFFF;
  font-size: 15px;
  line-height: 18.97px;
  letter-spacing: 2px;
  border-radius: 10px;
  height: 50px;
  padding: 0 24px;
  cursor: pointer;
  border: 2px solid #010101;
}

.athletes-profile-image,
.training_plan_image {
  min-width: 50px !important;
  max-width: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  object-fit: cover !important;
  border-radius: 50% !important;
  aspect-ratio: 900 / 600 !important;
}

.ant-card-body {
  padding: 24px !important;
}

/** tags **/

.tags-input,
.plan_tags {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background: none !important;
  width: 100%;
  padding: 0 !important;
  border: none !important;
}

.tags-input {
  min-height: 148px;
}

.tags-input:focus-within,
.plan_tags:focus-within {
  border: none !important;
}

.emails-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100% !important;
  flex-wrap: wrap !important;
}

.tags-input input[aria-label="placeholder"],
.plan_tags input[aria-label="placeholder"] {
  color: rgba(1, 1, 1, 0.4) !important;
}

.tags-input input,
.plan_tags input {
  flex: 1;
  border: none;
  height: 46px;
  background: none !important;
  color: #000000 !important;
  font-size: 16px;
  line-height: 20px;
  margin: 12px 0 0 0 !important;
}

.tags-input input:focus,
.plan_tags input:focus {
  outline: transparent;
  border-color: #ffffff !important;
  box-shadow: none !important;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.tag,
.error-tag {
  padding: 10px 16px 10px 20px;
  background: #EBEBEB;
  border-radius: 5px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2A2A2A;
  font-style: normal;
  font-weight: normal;
  list-style: none;
  margin: 14px 14px 0 0;
}

.tag {
  background: #ffffff;
  color: #2A2A2A;
}

.error-tag {
  background: #ED5252;
  color: #ffffff;
}

.tag-title,
.error-tag-title {
  font-size: 16px;
  line-height: 140%;
}

.ant-select-selection-item {
  color: #010101 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 18.5px !important;
  line-height: 115% !important;
  text-transform: none !important;
}

.ant-select-item-option-content {
  font-size: 16px !important;
  line-height: 140% !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 567px) {

  .tags-input,
  .plan_tags {
    width: calc(100vw - 32px);
  }
}

/* Toasts */

.success-toast {
  background-color: #27AE60;
}

.error-toast {
  background-color: #EB5757;
}

.info-text {
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  opacity: 0.5;
}

/* Management Screen Styles */

.management-tab-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 44px 0 0;
  z-index: 10;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

/* Gray box */

.left_widgets_area_640 {
  min-width: 640px;
}

.left_widgets_area_713 {
  min-width: 713px;
  width: 54%;
}

.right_widgets_area {
  margin-right: 32px;
  min-width: 292px;
  width: 25%;
}

.infoContainer {
  margin: 32px 0 0;
}

.grayContainer {
  margin: 12px 0;
}

.grayBox {
  background: #E5E5E5;
  padding: 16px 20px 16px 32px;
  min-width: 292px;
  width: 100%;
}

.grayBox {
  margin: 2px 0 0;
}

.grayBox .label {
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  width: 116px;
}

.grayBox .alertsLabel {
  font-size: 12px;
  line-height: 150%;
  font-weight: 600;
  color: #000000;
  width: 180px;
}

.grayBox .subtitle {
  font-size: 12px;
  line-height: 150%;
  color: #010101;
  width: 170px;
}

.grayBox .alertsSubtitle {
  font-size: 12px;
  line-height: 150%;
  color: #010101;
  width: 180px;
  margin: 4px 0 0;
}

.grayBox h5 {
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  font-weight: 700;
}

.grayBox:first-child {
  margin-top: 0px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.grayBox:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.borderLeft {
  margin: 12px 0 0;
  border-left: 2px solid #000000;
  height: auto;
}

.blackCircle {
  width: 12px;
  height: 12px;
  background: #000000;
  border-radius: 50%;
  margin-right: 16px;
}

.left_widgets_area .titleBar {
  margin: 18px 0 0 -7px;
}

.trainingContainer {
  padding: 32px 0;
  margin-left: 26px;
}

.left_widgets_area .borderLeft .title {
  font-size: 16px;
  line-height: 140%;
  color: #000000;
}

.left_widgets_area .trainingContainer {
  background: #FFFFFF;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 16px 20px;
  margin-top: 14px;
  margin-left: 26px;
}

.left_widgets_area .trainingContainer p,
.left_widgets_area .trainingContainer ul {
  width: 200px;
}

.left_widgets_area .trainingContainer ul {
  margin-left: 32px;
}

.left_widgets_area .nonTrainingCards {
  width: 100%;
  min-width: 576px;
}

.left_widgets_area .trainingCards {
  width: 100%;
  min-width: 682px;
}

.intensityDurationGraph {
  width: 240px;
  margin-left: 48px;
  position: relative;
}

.intensity {
  font-size: 10px;
  line-height: 150%;
  position: absolute;
  left: -32px;
  top: 45%;
  color: #8F8F8F;
  transform: rotate(-90deg);
}

.duration {
  font-size: 10px;
  line-height: 150%;
  color: #8F8F8F;
  display: flex;
  margin-left: 8px;
  align-items: center;
}

.intensityDurationGraph .label {
  font-size: 10px;
  line-height: 150%;
  color: #8F8F8F;
}

.arrow {
  border: solid #8F8F8F;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* .circleDarkOutline {
  border: 1px solid #000000;
  padding: 2px;
  border-radius: 50%;
  margin: 4px auto 0;
}

.circleLightOutline {
  border: 1px solid #8F8F8F;
  padding: 2px;
  border-radius: 50%;
  margin: 4px auto 0;
} */

.emptyCircle {
  background: #C9C9C9;
  width: 52px;
  height: 6px;
  margin: 2px 0;
  border-radius: 2px;
}

.filledCircle {
  background: #000000;
  width: 52px;
  height: 6px;
  margin: 2px 0;
  border-radius: 2px;
}

/*  */

.blackTiled {
  position: absolute;
  bottom: -6px;
  margin-left: -13px;
  z-index: 1;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 18px solid black;
}

.phase {
  position: absolute;
  bottom: -28px;
  z-index: 1;
  width: max-content !important;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
}

.graphContainer,
.graphContainerForSingle {
  margin: 10px 0 24px;
  display: flex;
  flex-wrap: wrap;
  height: 88px;
  position: relative;
  border-left: 1px dashed #ACACAC;
  border-bottom: 1px solid #ACACAC;
}

.graphContainerForSingle {
  border-right: 1px dashed #ACACAC;
}

.graphContainer img {
  max-height: 100%;
  height: 88px;
  margin-left: -1px;
  border-right: 1px dashed #ACACAC;
}

.graphContainerForSingle img {
  max-height: 100%;
  height: 72px;
  margin-top: 16px;
}

.stagName {
  font-size: 12px;
  line-height: 150%;
  color: #737373;
  margin-top: 4px;
}

.estrogen,
.progesterone,
.estrogen-dark,
.progesterone-light {
  font-size: 12px;
  line-height: 150%;
}

.estrogen-dark {
  color: #728860;
}

.estrogen {
  color: #F5B94B;
}

.progesterone-light {
  color: #8FAF74;
}

.progesterone {
  color: #708B5E;
}



.w_1100 {
  min-width: 1100px;
}

.w_1180 {
  min-width: 1180px;
}


/* Edit tabs */

.editTab {
  color: #000000;
  background: none;
  border: none;
  font-size: 16px;
  line-height: 150%;
  margin: 0 16px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  padding-bottom: 4px;
}

.editTab:first-child {
  margin-left: 0;
}

.editTab:last-child {
  margin-right: 0;
}

/* Arrows */
.positive_arrow svg,
.negative_arrow svg {
  height: 16px;
  width: 14px;
}

/* on focus link */

.focus_text {
  color: #000000;
  font-size: 16px;
  line-height: 140%;
}

.focus_text:hover {
  color: #010101;
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (max-width: 576px) {
  .main_content_area {
    padding: 0px 0 60px;
  }

  .editTab {
    font-size: 12px;
    line-height: 160%;
    margin: 0 6px;
    width: 80px;
  }
}