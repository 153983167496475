/* Active Button */
.black-button {
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    background-color: transparent;
    text-align: center;
    color: #ffffff !important;
    background-color: #000000;
    border-radius: 60px;
    height: 56px;
    letter-spacing: 2px;
    width: 100%;
    box-shadow: none !important;
    text-transform: uppercase;
    cursor: pointer !important;
    text-decoration: none;
    filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important;
}

.white-button-with-border {
    width: 100%;
    display: inline-block;
    position: relative;
    z-index: 1;
    border: 1px solid #010101;
    background-color: transparent;
    text-align: center;
    color: #010101 !important;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 2px;
    height: 46px;
    width: 100%;
    box-shadow: none !important;
    cursor: pointer !important;
    padding: 15.5px 0;
    border-radius: 25px;
    text-transform: uppercase;
    filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important;
}

/* Disable Button */
.black-button-disable {
    border: 0px;
    display: inline-block;
    position: relative;
    z-index: 1;
    background-color: #c1c1c1 !important;
    text-align: center;
    color: rgba(0, 0, 0, 0.2) !important;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 2px;
    height: 46px;
    width: 100%;
    padding: 15.5px 0;
    border-radius: 25px;
    text-transform: uppercase;
    filter: drop-shadow(0px 0.5px 4px rgba(0, 0, 0, 0.12)) !important;
}

.black-button p,
.black-button-disable p,
.white-button-with-border p {
    line-height: 120% !important;
    font-size: 12px !important;
}

.black-button-disable p {
    cursor: not-allowed !important;
}

.black-button:focus::after,
.white-button-with-border:focus::after,
.black-button-disable:focus::after {
    background: #e5e5e5;
    outline: none;
    -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
    -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
    box-shadow: inset 0px 0px 5px #c1c1c1;
    box-shadow: 0 0 0 #e5e5e5;
}

.black-button:hover p,
.black-button-disable:hover p {
    color: rgba(255, 255, 255, 0.7) !important;
}


.white-button-with-border:hover p {
    color: rgba(0, 0, 0, 0.5) !important;
}