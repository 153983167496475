.sidebar {
    flex: 0 0 280px !important;
    height: 100vh;
    max-width: 280px !important;
    position: fixed;
    width: 100%;
    background-color: #010101;
}

.logo-with-team-name {
    margin: 60px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menus-container {
    margin-top: 106px;
}

.sidebar-icon {
    width: 28px;
    height: 28px;
}

.logout-icon {
    width: 24px;
    height: 24px;
}

.sidebar-icon {
    margin-right: 16px;
}

.logout-icon {
    margin-right: 20px;
}

.logo-with-team-name .title {
    margin: 10px 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #ffffff;
}

.menu-item {
    margin: 0 0 16px;
    padding-left: 56px;
    height: 24px;
    display: flex;
    align-items: center;
}

.nav-link {
    font-size: 18px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.7) !important;
    display: flex;
    align-items: center;
}

.menu-item.active .nav-link {
    color: rgba(255, 255, 255, 1) !important;
}

.menu-item .nav-link:hover {
    color: rgba(255, 255, 255, 1) !important;
}

.bottom-navs {
    position: absolute;
    bottom: 3em;
}

.logout {
    margin: 0;
    height: 48px;
    padding-left: 56px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logout .nav-link:hover {
    color: rgba(255, 255, 255, 1) !important;
}


@media screen and (max-height: 600px) {
    .menus-container {
      margin-top: 25% !important;
    }
  }