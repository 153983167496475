@import "./styles/index.css";
@import "./main.css";

@font-face {
  font-display: swap;
  font-family: EuclidSquare;
  font-style: normal;
  font-weight: 400;
  src: local("EuclidSquare Regular"), local("EuclidSquare-Regular"),
    url(fonts/Euclid-Square/EuclidSquare-Regular.woff2) format("woff2");
}

@font-face {
  font-display: swap;
  font-family: EuclidSquare;
  font-style: normal;
  font-weight: 700;
  src: local("EuclidSquare Medium"), local("EuclidSquare-Medium"),
    url(fonts/Euclid-Square/EuclidSquare-Medium.woff2) format("woff2");
}

@font-face {
  font-display: swap;
  font-family: EuclidSquare;
  font-style: normal;
  font-weight: 900;
  src: local("EuclidSquare Bold"), local("EuclidSquare-Bold"),
    url(fonts/Euclid-Square/EuclidSquare-Bold.woff2) format("woff2");
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  -webkit-appearance: none;
  font-family: EuclidSquare, sans-serif;
  overflow-x: hidden;
  text-rendering: geometricPrecision;
}

.App {
  background-color: #ffffff;
  min-height: 100%;
  width: 100%;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
}

button {
  cursor: pointer;
}

input {
  width: 100%;
  font-family: "EuclidSquare";
  color: #010101;
  box-shadow: none;
  border: none;
  outline: none;
  padding: 0;
  font-size: 16px;
  line-height: 115%;
  font-style: normal;
  font-weight: normal;
}

textarea {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  font-family: "EuclidSquare";
  width: 100%;
}

ul,
li {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: 44px;
  line-height: 120%;
}

h2 {
  font-size: 32px;
  line-height: 120%;
}

h3 {
  font-size: 28px;
  line-height: 120%;
}

h4 {
  font-size: 24px;
  line-height: 140%;
}

h5 {
  font-size: 20px;
  line-height: 160%;
}

h6 {
  font-size: 18px;
  line-height: 160%;
}

p {
  font-size: 14px;
  line-height: 160%;
}

iframe {
  border: 0 !important;
  border-radius: 8px;
  box-shadow: none;
  outline: none;
}

img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  filter: none;
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -ms-filter: blur(0px);
  backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
}

::selection {
  color: #ffffff;
  background-color: #010101;
}

::placeholder {
  font-weight: normal;
}

/* General Styles */
.flex {
  display: flex;
}

.flex_wrap {
  flex-wrap: wrap;
}

.block {
  display: block;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyCenter {
  justify-content: center;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.alignStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.h-100vh {
  height: 100vh;
}

.w_96vw {
  width: 96vw;
  margin-right: 2rem;
}

.w-100vw {
  width: 100vw;
}

.grecaptcha-badge {
  visibility: hidden;
}

.dark_bg {
  background: #000;
  color: #fff;
}

.light_bg {
  background: #fff;
  color: #000;
}

.disabled_light_bg {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.pointer {
  cursor: pointer;
}

/* Preloader */

/* Error Toast */
.error_block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 0 16px;
}

.error_block_message {
  font-size: 16px;
  line-height: 1.4;
  color: #ffffff;
  text-align: center;
  margin: 0 16px;
}

.model_popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.4);
}

.model_popup .forgot_password_container {
  background: #ffffff;
  height: 350px;
  width: 500px;
  border-radius: 8px;
  padding: 24px;
}

/* Notification */
.notification_popup {
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 1000;
}

.notification_popup p {
  text-align: center;
  font-size: 16;
  line-height: 1.4;
  color: #ffffff;
  margin: 0px 24px;
}

@media screen and (min-width: 992px) {
  .desktop {
    display: flex !important;
  }

  .mobile {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }

  .model_popup .forgot_password_container {
    width: 96% !important;
  }

  .notification_popup {
    height: auto;
    padding: 20px 12px;
  }

  .notification_popup p {
    line-height: 1.66;
  }
}

.checkmark {
  position: absolute;
  top: -10px;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1.38px solid #000000;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox_container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.checkbox_container input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox_container:hover input ~ .checkmark {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */

.checkbox_container input:checked ~ .checkmark {
  background-color: #000000;
}

.checkbox_container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox_container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
