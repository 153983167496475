.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  z-index: 10;
}

.banner {
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  text-align: start;
  width: 100%;
}

.content,
.coach_content {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: 656px;
  width: 100%;
}

.content {
  max-width: 850px;
  margin: auto;
}

.coach_content {
  max-width: 616px !important;
  width: 100%;
  margin: 0 auto;
  min-height: 1060px;
}

.team_member_view_page {
  min-height: 820px !important;
  max-width: 616px !important;
  text-align: center;
  margin: 96px auto 0 !important;
}

.common_container {
  width: 100%;
  height: 100%;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.common_container .title {
  color: #ffffff;
  text-align: center;
  padding: 0px 16px;
}

@media screen and (max-width: 992px) {
  .coach_content {
    min-height: 520px;
  }

  .common_container .title {
    font-size: 38px;
    line-height: 1.256;
  }
}

.common_container .search_field {
  width: 100%;
  max-width: 746px;
  height: 100%;
  max-height: 45px;
  margin-top: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.common_container .search_field input {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  box-shadow: none;
  border: none;
  padding: 0 24px;
  font-size: 18px;
  line-height: 150%;
  color: #010101;
  font-family: inherit !important;
}

.common_banner_content {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  max-width: 1100px;
  margin: 64px 0 120px;
  position: relative;
}

.common_banner_left_content,
.common_banner_right_content {
  width: 100%;
  max-width: 455px;
}

.common_banner_content li:first-child {
  font-size: 14px;
  line-height: 150%;
  margin: 8px 0 0;
  color: #010101;
}

.common_banner_content li {
  font-size: 14px;
  line-height: 150%;
  color: #010101;
}

.common_banner_content p {
  font-size: 14px;
  line-height: 150%;
  margin: 10px 0 0;
  color: #010101;
}

.common_banner_content a {
  color: #010101;
  margin-right: 4px;
  margin-left: 4px;
  text-transform: none;
}

.common_banner_content a:hover {
  color: #010101;
  text-decoration: underline;
}

.member_name {
  font-size: 72px;
  line-height: 110%;
  color: #ffffff;
  text-align: center;
  padding: 32px 16px 0;
}

.designation {
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  color: #ffffff;
  margin: 20px 0;
}

/* list container */

.list_container {
  display: flex;
  flex-direction: column;
  margin: 64px 0 120px;
  width: 100%;
  max-width: 980px;
}

.list_label {
  margin: 40px 0;
  color: #000000;
  text-align: left;
  text-transform: capitalize;
}

.list_dropdown_box {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  width: 100%;
  border-bottom: 1px solid rgba(1, 1, 1, 0.4);
}

.list_dropdown_box a {
  text-decoration: none;
}

.list_dropdown_box .title {
  font-size: 18px;
  line-height: 115%;
  color: #000000;
  cursor: pointer;
}

.list_dropdown_box .description {
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  margin: 25px 0;
}

@media screen and (max-width: 576px) {
  .list_label {
    font-size: 26px !important;
    line-height: 140% !important;
  }

  .list_dropdown_box .title {
    font-size: 16px !important;
    line-height: 140% !important;
    width: 80% !important;
  }

  .list_dropdown_box .description {
    font-size: 13px;
    line-height: 145%;
  }
}

/* Pricing banner */

.pricing_banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 752px;
  margin: 0 auto;
}

.pricing_row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing_banner .pricing_card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 0 0;
  height: 556px;
  width: 100%;
  max-width: 390px;
  min-width: 390px;
  margin: 0 21px;
  background: #ffffff;
  border-radius: 12px;
}

.pricing_banner .pricing_card:first-child {
  margin-left: 0px;
}

.pricing_banner .pricing_card:last-child {
  margin-right: 0px;
}

.bullets_for_life_support,
.gift_bullets_for_life_support {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-left: 1%;
}

.pricing_card ul {
  margin: 24px 0 32px;
  width: 280px;
}

.life_stage_supports {
  margin: 80px auto 0;
  width: 100%;
  max-width: 678px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.life_stage_supports h2,
.life_stage_supports h3 {
  text-align: center;
}

.bullets_for_life_support ul,
.gift_bullets_for_life_support ul {
  width: 100%;
}

.pricing_card ul li,
.bullets_for_life_support ul li,
.gift_bullets_for_life_support ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0 0;
  font-size: 16px;
  line-height: 140%;
  color: #565656;
}

.bullets_for_life_support ul li {
  margin: 24px 0 0 !important;
}

.pricing_card ul li span,
.bullets_for_life_support ul li span,
.gift_bullets_for_life_support ul li span {
  margin-right: 10px;
  height: 24px;
  min-width: 24px;
}

.bullets_for_life_support ul li span {
  margin-right: 24px;
}

.payment {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 32px;
}

.payment h4 {
  font-size: 27px;
  line-height: 120%;
  color: #000000;
}

.payment p {
  font-size: 16px;
  line-height: 140%;
  color: #565656;
  margin-top: 8px;
}

.saving {
  position: absolute;
  right: 35px;
  bottom: 130px;
  height: 64px;
  width: 64px;
  background: #e8494e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.saving p {
  margin-top: 14px;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
}

.wild_sync {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  margin: 120px auto 60px;
}

.wild_sync .image_box {
  position: relative;
  width: 100%;
  max-width: 745px;
  height: 900px;
}

.wild_sync .image_overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.wild_sync .sync_container {
  background: #000000;
  width: 100%;
  height: 100%;
  min-height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.wild_sync .sync_content {
  width: 100%;
  height: 100%;
  max-width: 530px;
  margin: auto;
}

.wild_sync .sync_content h2,
.wild_sync .sync_content h3 {
  margin: 24px 0;
}

.wild_sync .sync_content ul {
  margin: 40px 0;
}

.wild_sync .sync_content li {
  font-size: 16px;
  line-height: 180%;
}

.wild_sync .sync_content ul span {
  margin: 32px 0 0;
  display: flex;
  align-items: center;
}

.wild_sync .sync_content ul span img {
  margin-right: 24px;
}

/*  */

.research_content {
  width: 100%;
  max-width: 820px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 120px;
}

.contained_image {
  object-fit: contain !important;
}

.research_content .research_container {
  width: 100%;
  max-width: 404px;
}

.research_content .research_container p,
.research_content .research_bullets li {
  font-size: 16px;
  line-height: 140%;
  margin: 32px 0;
}

.research_content .research_bullets li {
  list-style-type: disc !important;
  margin: 16px 0px 16px 20px !important;
  font-weight: 400 !important;
}

.research_content .research_container .collab_button {
  width: 290px;
  margin: 32px 0 0;
}

.tab_content_for_life_stage_supports {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.tab_content_for_life_stage_supports .label {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 120%;
}

@media screen and (max-width: 992px) {
  .common_banner_content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 60px auto 0;
    padding: 0 32px;
  }

  .pricing_banner .pricing_card {
    min-width: 340px;
    max-width: 340px;
    padding: 44px 0 24px;
    height: 100%;
  }

  .content {
    min-height: 100%;
  }

  .list_container > div {
    max-width: 437px !important;
    width: 92%;
    margin: 0 auto;
  }

  .pricing_banner {
    margin: 64px auto 0;
  }

  .bullets_for_life_support ul,
  .gift_bullets_for_life_support {
    padding: 0 24px;
  }

  .wild_sync .sync_container {
    padding: 0 24px;
    min-height: 1020px;
  }

  .tab_content_for_life_stage_supports {
    max-width: 100% !important;
    padding: 0 16px;
    justify-content: center;
  }

  .tab_content_for_life_stage_supports .label {
    font-size: 14px;
    line-height: 140%;
  }

  .tab_content_for_life_stage_supports .category {
    margin: 8px 8px 0;
  }

  .tab_content_for_life_stage_supports .black_category {
    margin: 8px 6px 0;
  }

  .wild_sync .sync_container h2,
  .wild_sync .sync_container h3 {
    text-align: center;
  }

  .research_content .research_container {
    padding: 0 24px;
    margin: 0 auto;
  }

  .research_content .research_container h2 {
    text-align: center;
  }

  .research_content .research_container .collab_button {
    width: 290px;
    margin: 32px auto 0;
  }
}

.pricing_banner p::selection,
.wild_sync h2::selection,
.wild_sync h3::selection,
.wild_sync li::selection {
  color: #010101 !important;
  background: #ffffff !important;
}
