.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 1080px;
  position: relative;
  overflow: hidden;
  background-color: #000;
}

.main .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.tiled {
  position: absolute;
  bottom: 0;
  right: 0;
  border-left: 100vw solid transparent;
  border-bottom: 120px solid #ffffff;
  z-index: 1;
}

.image {
  background-image: url(/assets/images/main-image.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100%;
  min-height: 1080px;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto 50px;
  z-index: 10;
}

.gympass_banner_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  margin: -160px auto 0;
  z-index: 10;
}

.gympass_features_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  max-width: 784px;
  text-align: center;
  margin: 0 auto;
  z-index: 10;
}

/* Tab screen styles */

.tab_screen {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.tab_content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.image_slider_desktop,
.image_slider_gympass {
  width: 100%;
  max-width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_slider_desktop {
  margin-top: -220px;
}

.align_center {
  align-items: center;
}

.tab_layout {
  width: 100%;
  max-width: 550px;
  margin-left: 98px;
  display: flex;
  flex-direction: column;
}

.tab_layout h3 {
  margin-bottom: 32px;
  font-weight: 700;
}

.backward {
  margin-right: 48px;
}

.forward {
  margin-left: 48px;
}

.backward,
.forward {
  background-color: #010101;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.device_frame {
  filter: drop-shadow(0px 30.325px 52.0505px rgba(0, 0, 0, 0.25));
  width: 296px !important;
  height: 601px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.tabs .tab {
  margin-right: 16px;
  cursor: pointer;
}

.tabs .tab p {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #010101;
  font-size: 14px;
  line-height: 120%;
  font-weight: 700;
  padding: 0 16px;
}

.tabs_content {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}

.sliding_content_active,
.sliding_content_inactive {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 21px;
}

.sliding_content_active {
  width: 396px;
}

.sliding_content_active .sliding_active_arrow {
  margin-right: 24px;
}

.active_content {
  color: #010101;
  width: 396px;
  margin-left: 20px !important;
}

.inactive_content,
.sliding_content_inactive {
  color: rgba(1, 1, 1, 0.2);
  width: 420px;
}

.social_apps_logos {
  display: flex;
  width: 100%;
  padding-top: 36px;
  max-width: 290px;
  justify-content: space-between;
}

.social_apps_logos a {
  height: 140px;
  height: 49px;
}

/* Life Stage Support styles */

.life_stage_support {
  position: relative;
  height: 100%;
  width: 100%;
}

.support_overlay {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 1540px;
}

.support_overlay .support_bg {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 1540px;
  max-height: 1550px;
  object-position: center 20%;
}

.support_overlay_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.8);
}

.support_content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  z-index: 10;
}

.vertical_row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 392px;
}

.vertical_line {
  height: 8px;
  background: #ffffff;
  width: 2px;
  margin-bottom: 8px;
}

.vertical_half_line_top {
  height: 4px;
  background: #ffffff;
  width: 2px;
  margin-bottom: 0px;
}

.vertical_half_line_bottom {
  height: 4px;
  background: #ffffff;
  width: 2px;
  margin-bottom: 8px;
}

.support_details {
  display: flex;
  justify-content: center;
  align-items: center;
}

.support_icon_box {
  position: relative;
}

.support_icon {
  background: #ffffff;
  height: 73px;
  width: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.support_icon_details_box {
  position: absolute;
  bottom: 80px;
  width: 100%;
  height: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 540px;
  max-width: 540px;
  min-height: 320px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 24px 40px;
  z-index: 10;
  box-shadow: 0px 25px 47px rgba(0, 0, 0, 0.3);
}

.support_icon_details {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.support_icon_details h3 {
  color: #010101;
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
  width: 100%;
}

.support_icon_details p {
  margin: 8px 0 0;
  color: #010101;
}

.support_icon_details ul li {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
}

.support_icon_details ul li p {
  margin: 0 0 0 8px !important;
  font-size: 16px;
  line-height: 140%;
}

.support_icon_empty {
  background: #ffffff;
  height: 29px;
  width: 29px;
  border-radius: 50%;
}

.support_icon img {
  transform: scale(1);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.support_icon img:hover {
  transform: scale(1.1);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.vertical_row_left {
  justify-content: flex-end;
}

.vertical_row_left,
.vertical_row_right {
  display: flex;
  width: 160px;
  align-items: center;
  justify-content: center;
}

.vertical_row_left_content,
.vertical_row_right_content {
  display: flex;
  flex-direction: column;
}

.vertical_row_right {
  justify-content: flex-start;
}

.vertical_row_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 24px;
}

.timeline,
.timeline_fade {
  font-size: 20px;
  line-height: 140%;
  color: #ffffff;
  font-weight: 600;
}

.description,
.description_fade {
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
}

@media screen and (max-width: 992px) {
  .vertical_row_left,
  .vertical_row_right {
    width: 120px;
  }

  .vertical_row_center {
    margin: 0 16px;
  }

  .timeline,
  .timeline_fade {
    font-size: 17px;
  }
}

.timeline_fade,
.description_fade {
  opacity: 0.6;
}

.close_dialog {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  width: 100%;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #000;
}

/* Wild sync - homepage */

.wild_sync {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 56px;
  height: 420px;
  width: 100%;
  max-width: 1100px;
}

.wild_sync .wild_sync_title {
  color: #000000;
}

.wild_sync .wild_sync_description {
  color: #000000;
  font-size: 18px;
  line-height: 140%;
  margin: 8px 0 40px;
}

.wild_sync_logos {
  margin: 32px 0 0;
}

.wild_sync_logos,
.wild_partner_logos {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}

.wild_partner_logos {
  max-width: 920px;
  margin-top: 24px;
}

.wild_sync_logos img {
  object-fit: contain !important;
  padding: 12px 16px !important;
}

.wild_partner_logos a {
  margin: 28px 18px !important;
}

.wild_sync_logos img:first-child {
  margin-left: 0px !important;
}

.wild_sync_logos img:last-child {
  margin-right: 0px !important;
}

/* 

.wild_sync_logos>div:first-child {
  margin-left: 0px !important;
}

.wild_sync_logos>div:last-child {
  margin-right: 0px !important;
} */

/* Testimonials */

.testimonial_container {
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 768px;
}

.wild_partner_banner {
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 576px;
}

/* Newsletter styles */

.newletter_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 520px;
}

.newletter_box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 618px;
}

.newletter_box > p {
  color: #000;
  width: 222px;
  font-size: 27.54px;
  line-height: 120%;
}

.newletter_box input {
  background-color: #fff;
  color: #000 !important;
  border-bottom: 1px solid #ffffff !important;
}

.newletter_box input::placeholder {
  color: #737373 !important;
}

/* News styles */

.latest_news {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  background: #000;
  min-height: 1020px;
  padding: 56px 0 0;
}

.latest_news .news_row {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 80px;
}

.news_box {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 437px;
  align-items: flex-start;
  justify-content: flex-start;
  text-decoration: none;
}

.news_box .image_shadow_container {
  filter: drop-shadow(0px 25px 47px rgba(0, 0, 0, 0.3));
  background: #ffffff;
  padding: 10px;
}

.news_content {
  width: 100%;
  padding: 10px;
  margin-top: 30px;
}

.news_content .date {
  font-size: 14px;
  line-height: 150%;
  color: rgba(1, 1, 1, 0.4);
}

.news_content h4 {
  color: #010101;
  margin: 10px 0 0;
  text-transform: capitalize;
}

.news_content > p {
  color: #010101;
  margin: 20px 0 0;
  font-size: 16px;
  line-height: 140%;
}

.read_more_box,
.latest_news_button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: max-content;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: none !important;
}

.latest_news_button {
  margin: 80px 0 !important;
}

.read_more_box {
  justify-content: flex-start;
}

.read_more_box p {
  color: rgba(1, 1, 1, 0.8);
  margin-right: 16px;
}

.latest_news_button {
  justify-content: center;
  margin: 56px 0 0;
}

/* Coach subscribe section styles */

.coach_section {
  height: 100%;
  width: 100%;
  min-height: 1080px;
  padding: 56px 0 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.coach_section_title {
  color: #010101;
  margin-top: 80px;
}

.coach_section_description {
  color: #010101;
  margin: 40px 0 0;
  font-size: 27.54px;
  line-height: 120%;
}

.coach_image_box {
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 78px 0 0;
  z-index: 100;
  background: #ffffff;
  padding: 20px;
  box-shadow: 2px 0px 27px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 0px 27px 10px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 2px 0px 27px 10px rgba(0, 0, 0, 0.15);
  max-width: 673px;
  border-radius: 20px;
}

.athletes_cover_images_container {
  position: absolute;
  right: 0;
  bottom: 60px;
  transform: translateX(120px);
}

.coach_section .coach_form {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.coach_form .coach_app_overview {
  width: 100%;
  max-width: 452px;
}

.coach_app_overview p {
  color: #010101;
}

.coach_app_overview .bullets {
  margin-top: 40px;
  max-width: 394px;
}

.coach_app_overview .bullets p {
  font-weight: bold;
  color: #010101;
}

.coach_form .coach_app_subscribe {
  width: 100%;
  max-width: 520px;
}

.bullets_box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 80%;
}

.bullets_container_left,
.bullets_container_right {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.bullets_container_left {
  max-width: 452px;
  padding: 0 24px;
}

.bullets_container_right {
  max-width: 332px;
}

.bullets_container_left ul li {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 0 8px;
  margin: 0;
}

.bullets_container_left ul li p {
  margin: 0 0 0 12px !important;
  font-size: 17px;
  line-height: 150%;
  font-weight: 700;
}

.bullets_container_left .dot {
  width: 100%;
  height: 100%;
  min-width: 6px;
  min-height: 6px;
  max-width: 6px;
  max-height: 6px;
  border-radius: 50%;
  background: #000;
  margin-top: 8px;
}

/* Team section styles */

.team_banner {
  height: 100%;
  width: 100%;
  min-height: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000;
  position: relative;
}

.team_title {
  color: #fff;
  margin-top: 146px;
}

.team_description {
  color: #fff;
  margin: 40px 0 0;
  font-size: 27.54px;
  line-height: 120%;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .show_for_desktop {
    display: flex !important;
  }

  .show_for_mobile {
    display: none !important;
  }

  .tab_content {
    margin-bottom: 240px;
  }
}

/* Premium banner */

.premium_text,
.normal_text,
.welcome_text {
  text-align: center;
  color: #ffffff;
}

.premium_text {
  font-size: 10.3212px;
  line-height: 140%;
}

.welcome_text {
  font-size: 45px;
  text-transform: capitalize;
  line-height: 120%;
  margin: 30px 0 0;
}

.normal_text {
  font-size: 16px;
  line-height: 140%;
  padding: 0 32px;
  text-align: center;
  margin: 20px 0 0;
}

.features_row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 784px;
  margin: 50px 0;
}

.feature_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: auto;
  width: 100%;
  max-width: 332px;
  padding: 0 24px;
}

.feature_image_container {
  height: 88px;
  width: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  border-radius: 50%;
  box-shadow: 0px 8.25px 23.375px rgba(0, 0, 0, 0.25);
}

.feature_box p {
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  margin: 20px 0;
}

/* about page */

.wild_sync_light_logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.wild_sync_light_logos img {
  object-fit: contain !important;
  padding: 12px 6px !important;
}

.wild_sync_light_logos img:first-child {
  margin-left: 0px !important;
}

.wild_sync_light_logos img:last-child {
  margin-right: 0px !important;
}

.coach_section_margin {
  margin: 180px 0 0;
}

@media screen and (max-width: 992px) {
  .show_for_desktop {
    display: none !important;
  }

  .show_for_mobile {
    display: flex !important;
  }

  .team_banner {
    padding: 50px 0 80px;
  }

  .landing_banner_content {
    margin: -80px auto 0;
  }

  .gympass_banner_content {
    margin: -220px auto 0;
  }

  .tab_layout {
    margin-left: 0px;
  }

  .features_column {
    margin: 50px auto;
  }

  .features_row {
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
  }

  .feature_box {
    margin: 26px auto;
  }

  .welcome_text {
    font-size: 27px;
    line-height: 120%;
  }

  .newletter_box {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 50px auto;
    max-width: 90% !important;
  }

  .newletter_container_left {
    padding: 0px 24px 48px;
  }

  .newletter_container_right,
  .newletter_container_right h4 {
    text-align: left;
    width: 100%;
    line-height: 140%;
    max-width: 332px;
  }

  .news_row {
    width: 96% !important;
    max-width: 437px;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
  }

  .bullets_box {
    flex-direction: column;
    align-items: center;
    max-width: 90%;
  }

  .bullets_container_right {
    margin-top: 40px;
  }

  .latest_news {
    min-height: 1560px !important;
  }

  .news_box {
    margin: 0 auto 50px;
  }

  .coach_section_title,
  .coach_section_description,
  .team_title,
  .team_description {
    max-width: 437px;
    width: 96%;
    text-align: center;
  }

  .coach_section_description {
    margin: 40px 0;
  }

  .coach_section_title {
    line-height: 120%;
  }

  .team_members_row {
    flex-direction: column;
    justify-content: center;
  }

  .wild_sync {
    height: 520px;
  }

  .wild_sync_logos {
    margin: 32px !important;
  }

  .wild_sync_description {
    width: 90%;
    text-align: center;
  }

  .wild_sync_logos img {
    object-fit: contain !important;
  }

  .coach_section_margin {
    margin: 56px 0 0;
  }

  .testimonial_container {
    min-height: 992px;
  }

  .wild_partner_banner {
    min-height: 900px;
  }

  .support_overlay {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 1240px;
  }

  .support_overlay .support_bg {
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 1340px;
    max-height: 1340px;
    object-position: center 20%;
  }
}

@media screen and (max-width: 768px) {
  .support_icon_details_box {
    min-width: 340px;
    max-width: 360px;
    min-height: 380px;
    border-radius: 20px;
    padding: 16px 16px 16px 20px;
  }

  .support_icon_details > h3 {
    font-size: 18px;
  }

  .support_icon_details > p {
    font-size: 16px;
  }

  .support_icon_details ul li {
    margin: 6px 6px 6px 0;
    position: relative;
  }

  .support_icon_details ul li div {
    position: absolute;
    top: 8px;
  }

  .support_icon_details ul li p {
    margin-left: 16px !important;
    font-size: 14px;
  }
}

/* Highlight content having black background */

.team_title::selection,
.team_description::selection,
.newletter_box p::selection,
.newletter_box input::selection {
  background: #ffffff !important;
  color: #010101 !important;
}
