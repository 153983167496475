#header {
    -ms-flex-pack: justify;
    align-items: center;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    height: 180px;
}

#header>div {
    max-width: 90%;
}

#header a,
#header p {
    text-decoration: none;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 2px;
    font-weight: normal;
    text-transform: uppercase;
}

#header .logo {
    cursor: pointer;
}


@media screen and (max-width: 576px) {
    #header {
        height: 108px;
    }

    #header>div {
        max-width: 90%;
    }

    #header p {
        font-size: 14px !important;
    }
}