.footer {
    width: 100%;
    height: 100%;
    background: #010101;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer a {
    text-decoration: none;
    cursor: pointer;
    color: #fff;
    font-size: 17px;
    line-height: 150%;
    font-weight: normal;
}

.footer_row {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 1100px;
    width: 100%;
}

.left ul,
.icons {
    align-items: center;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    width: 100%;
}

.icons {
    justify-content: center;
    align-items: center;
    margin: 24px 0 0;
}

.left ul li {
    padding: 0 16px;
}

.icons a {
    padding: 0 16px;
}

.left ul li:first-child,
.icons a:first-child {
    padding-left: 0px !important;
}

.left ul li:last-child,
.icons a:last-child {
    padding-right: 0px !important;
}

.right {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.copyrights {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1100px;
    margin: 32px 0 0;
}

.copyrights p {
    margin: 0;
    color: #ffffff;
    font-size: 14px;
    line-height: 150%;
    word-spacing: 2px;
}

.help_box {
    display: flex;
    flex-direction: column;
    background: #000000;
    width: 100%;
    max-width: 352px;
    color: #ffffff;
    text-align: center;
    margin: 0px 0 64px;

}

.help_icon_box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}

.help_icon_box h3 {
    margin-left: 10px;
}

.help_box span a {
    margin-left: 6px;
    text-decoration: underline !important;
}

.footer a::selection,
.footer p::selection,
.footer h3::selection {
    background: #ffffff !important;
    color: #010101 !important;
}

@media screen and (min-width: 992px) {
    .show_for_desktop {
        display: flex;
    }

    .show_for_mobile {
        display: none;
    }

    .footer {
        padding: 60px 0 120px;
    }
}


@media screen and (max-width: 992px) {
    .show_for_desktop {
        display: none;
    }

    .help_box {
        margin: 60px 0;
    }

    .show_for_mobile {
        display: flex;
    }

    .footer_row {
        justify-content: center;
        height: 100%;
        padding: 40px 0;
    }

    .footer {
        padding-bottom: 80px;
    }

    .copyrights {
        text-align: center;
        max-width: 437px;
        width: 90%;
    }
}