.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.job_details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 980px;
  text-align: center;
  margin: 64px auto;
}

.job_details p {
  margin: 16px 0 6px;
  text-align: justify !important;
}


.job_details ul {
  margin: 0 0 0 16px;
}

.job_details ul,
.job_details li {
  list-style-type: inherit !important;
  text-align: left;
}

.job_details li {
  margin: 8px 0 0;
}

.team_member_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 980px;
  text-align: center;
  margin: 120px auto 0;
}

.gift_content_container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 64px 40px 0;
}

.thankYou_content_container {
  height: 100%;
  width: 100%;
  max-width: 425px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 64px 40px 0;
}

.thankYou_content_container .title {
  font-size: 22px;
  line-height: 140%;
  margin: 0px 0px 40px -16px;
  color: #000000
}

.thankYou_content_container .thankYou_list li {
  list-style-type: disc !important;
  margin: 8px 0 !important;
  font-size: 16px;
  line-height: 140%;
}

.team_member_details h1,
.team_member_details h2,
.team_member_details h3,
.team_member_details h4,
.team_member_details h5,
.team_member_details h6 {
  margin: 25px 0;
  line-height: 1.256;
}

.team_member_details p {
  font-size: 16px;
  line-height: 150%;
  margin: 25px 0;
}

.team_member_details a {
  color: #010101;
}

.team_member_details img {
  max-height: 520px;
}

.show_for_desktop,
.show_for_mobile {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .show_for_desktop {
    display: flex !important;
  }

  .show_for_mobile {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .show_for_desktop {
    display: none !important;
  }

  .show_for_mobile {
    display: flex !important;
  }

  .job_details {
    width: 90%;
  }

  .gift_content_container {
    flex-direction: column;
    padding: 56px 20px 0;
  }

  .thank_you_card {
    width: 96% !important;
    padding: 16px !important;
    min-height: 576px !important;
    text-align: center !important;
  }

  .tick_circle {
    height: 120px !important;
    width: 120px !important;
  }
}

/* Thank you Modal */
.thank_you_modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
}

.thank_you_card {
  background: #ffffff;
  min-height: 520px;
  width: 576px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.thank_you_card>p {
  text-align: center;
  margin: 20px auto;
  width: 75%;
}

.tick_circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #27AE60;
  border-radius: 50%;
  height: 160px;
  width: 160px;
}