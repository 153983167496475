.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #00000070;
}

.main .after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.main .home_after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.tiled {
  position: absolute;
  bottom: -1px;
  right: -1px;
  border-left: 100vw solid transparent !important;
  border-bottom: 160px solid #ffffff;
  z-index: 1;
}

.team_tiled {
  position: absolute;
  bottom: -1px;
  right: -1px;
  border-left: 100vw solid transparent !important;
  border-bottom: 160px solid #000000;
  z-index: 1;
}

.image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 100%;
  width: 100%;
}

.homepage_banner,
.homepage_background_image {
  min-height: 880px;
  min-width: 100vw;
}

.community_banner,
.community_background_image {
  min-height: 100vh;
  min-width: 100vw;
}

.teampage_banner,
.teampage_background_image {
  min-height: 1600px;
}

.gympasspage_background_image,
.gympasspage_banner {
  min-height: 984px;
}

.privacypage_banner,
.privacypage_background_image,
.termspage_banner,
.termspage_background_image {
  min-height: 672px;
}

.aboutpage_banner,
.aboutpage_background_image {
  min-height: 1120px;
}

.giftpage_banner,
.thankyoupage_banner,
.giftpage_background_image,
.thankyoupage_background_image {
  min-height: 520px;
}

.teampage_memberview,
.teampage_memberview_background_image {
  min-height: 730px;
}

.coachpage_banner,
.coachpage_background_image {
  min-height: 856px;
}

.methodpage_banner,
.methodpage_background_image {
  min-height: 984px;
}

.coaches_banner,
.coaches_banner_background_image {
  min-height: 900px;
}

.methoddetailpage_banner,
.methoddetailpage_background_image {
  min-height: 648px;
}

.blogspage_banner,
.blogspage_background_image,
.jobspage_banner,
.jobspage_background_image {
  min-height: 749px;
}

.marketplace_banner,
.marketplace_background_image {
  min-height: 320px;
}

.jobspage_jobview,
.jobspage_jobview_background_image {
  min-height: 656px;
}

.faqpage_faqview,
.faqpage_faqview_background_image {
  min-height: 356px;
}

.gympasspage_background_image,
.privacypage_background_image,
.termspage_background_image,
.aboutpage_background_image,
.giftpage_background_image,
.thankyoupage_background_image {
  background-image: url(https://wild-blog-strapi.s3.amazonaws.com/home_ff0b9812d3.jpg?7765595.7);
}

/* .giftpage_background_image,
.thankyoupage_background_image {
    background-image: url(https://media.wild.ai/assets/images/gift-background.jpg);
} */

.coachpage_background_image {
  background-image: url(https://wild-blog-strapi.s3.amazonaws.com/image_72_e4e5bd6e8c.jpg?610431.8000000007);
}

.teampage_background_image,
.jobspage_background_image,
.teampage_memberview_background_image,
.jobspage_jobview_background_image {
  background-image: url(https://wild-blog-strapi.s3.amazonaws.com/teams_dba5cb5187.jpg?3958210.2);
}

.methodpage_background_image,
.methoddetailpage_background_image {
  background-image: url(https://wild-blog-strapi.s3.amazonaws.com/wild_wiki_68e40464b2.jpg?213734.90000000037);
}

.coaches_banner_background_image {
  background-image: url(https://res.cloudinary.com/farooq-cloud-db/image/upload/v1686566406/pexels-mica-asato-1198174_dupmdz.jpg);
}

.blogspage_background_image {
  background-image: url(https://wild-blog-strapi.s3.amazonaws.com/blogs_f792d728ce.jpg?11850074.2);
}

.marketplace_background_image {
  background-image: url(https://wild-blog-strapi.s3.amazonaws.com/wild_wiki_68e40464b2.jpg);
}

@media screen and (max-width: 992px) {
  .homepage_banner,
  .homepage_background_image {
    min-height: 920px;
  }

  .aboutpage_banner,
  .aboutpage_background_image {
    min-height: 1366px;
  }

  .giftpage_banner,
  .giftpage_background_image {
    min-height: 800px;
  }

  .thankyoupage_banner,
  .thankyoupage_background_image {
    min-height: 600px;
  }

  .tiled {
    display: none;
  }

  .blogspage_banner,
  .blogspage_background_image {
    min-height: 680px;
  }

  .marketplace_banner,
  .marketplace_background_image {
    min-height: 260px;
  }

  .jobspage_banner,
  .jobspage_background_image,
  .coachpage_banner,
  .coachpage_background_image,
  .privacypage_banner,
  .privacypage_background_image,
  .termspage_banner,
  .termspage_background_image {
    min-height: 520px;
  }

  .teampage_banner,
  .teampage_background_image {
    min-height: 3200px;
  }
}
