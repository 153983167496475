.team_selector,
.date_selector,
.report_date_selector {
  position: relative;
  height: 45px;
  min-width: 204px;
  max-width: 206px;
}

.report_date_selector,
.report_date_selector .listing {
  min-width: 260px;
  max-width: 264px;
}

.team_selector {
  min-width: 204px;
  max-width: 206px;
}

.date_selector {
  min-width: 182px;
  max-width: 184px;
}

.report_date_selector .teams-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.report_date_selector .teams-list::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

.report_date_selector .teams-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.selectory_arrow_component {
  margin-top: 6px;
}

.report_date_selector>p {
  font-size: 16px;
  line-height: 120%;
  margin-right: 8px;
}

.report_date_selector .teams-list {
  min-width: 244px;
  max-width: 250px;
}

.selectory_arrow_component img,
.date_selector img,
.report_date_selector img {
  cursor: pointer;
}

.pointer_not_allowed {
  cursor: not-allowed !important;
}

.selected_team {
  font-style: normal;
  font-size: 24px;
  line-height: 120%;
  color: #000000;
  margin: 0;
  cursor: pointer;
  text-align: start !important;
}

.listing {
  position: absolute;
  left: 0;
  top: 45px;
  border-radius: 10px;
  z-index: 99;
  padding: 12px;
  width: 100%;
  min-width: 204px;
  max-width: 206px;
  background: white;
  box-shadow: 1px 2px 8px #01010160;
}

.teams-list {
  min-width: 204px;
  max-width: 206px;
  width: 100%;
  border-radius: 8px;
  height: 100%;
  max-height: 160px;
  overflow-y: auto;
}

.team-names {
  color: #01010180 !important;
  cursor: pointer !important;
  font-size: 16px;
  line-height: 120%;
  padding: 12px 0 0;
}

.team-names.active,
.team-names:hover {
  color: #010101 !important;
}

/* Sorting arrows */
.sorting_arrows {
  margin-top: -6px;
  margin-left: 3px;
}

.sorting_arrows span {
  height: 8px;
  width: 8px;
  cursor: pointer;
}