.main_container {
    height: 100%;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 40px;
    margin: auto;
    max-width: 1560px !important;
    z-index: 100;
}

@media print {
    body {
        overflow-y: hidden;
        /* Hide vertical scrollbar */
        overflow-x: hidden;
        /* Hide horizontal scrollbar */
    }

    .main_container {
        background: #fff;
        -webkit-print-color-adjust: exact;
    }
}


/* Header */

.title {
    color: #000000;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
}

.cycle_date {
    margin-top: 8px;
}

.seperator {
    margin: 0 8px !important;
}

/* Common Header */
.common_label {
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin: 32px 0 0;
    width: 100%;
}

.common_label_with_underline {
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin: 32px 0 0;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid #000000;
}

.common_subtitle_without_underline {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding: 20px 0 16px;
}

.common_description {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding: 16px 0;
}

.common_subtitle {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    width: 90%;
    padding: 16px 0;
}

/* Cycle Checkins */
.bullet_container {
    margin: 0 16px;
    min-width: 260px;
    max-width: 300px;
}

.bullet_container:first-child {
    margin-left: 0px;
}

.cycle_days {
    font-size: 28px;
    line-height: 28px;
    font-weight: 900;
}

.trend_value {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-top: -20px;
    margin-left: 4px;
}

.bullet {
    font-size: 14px;
    line-height: 20px;
    margin: 10px 0 0;
}

.scrollable {
    overflow: scroll !important;
}

.cycle_bullets_card {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 32px 0 48px;
}

.report_download_button_text {
    margin: -4px 0 0;
    color: "#ffffff";
    font-size: 12px !important;
    line-height: 120% !important;
}

.morning_header {
    background: #F4F4F4;
    border-radius: 6px;
    padding: 12px;
    max-width: 425px;
    min-width: 426px;
}

.tooltip {
    position: absolute;
    left: 0;
    bottom: 0;
    color: #000000;
    text-align: center;
    z-index: 100;
    border-radius: 4px;
    width: 48px;
    height: 24px;
    margin-left: -12px;
}


.tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000000 transparent transparent transparent;
}

.mtb_32 {
    margin: 32px 0;
}

.grey_box {
    background: #F4F4F4;
    border-radius: 4px;
    padding: 12px;
}

@media screen and (max-width: 768px) {

    .main_container {
        padding: 48px 24px;
    }

    .desktop_report_card {
        display: none !important;
    }

    .mtb_32 {
        margin: 16px 0;
    }

    .cycle_bullets_card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin: 16px 0 48px;
    }

    .bullet_container {
        margin: 16px 0;
        max-width: 100%;
    }

    .mobile_report_card {
        display: flex !important;
    }

    .title {
        font-size: 18px;
    }

    .cycle_date {
        margin-top: 0px;
    }

    .morning_checkin_header {
        flex-direction: column;
    }

    .morning_header {
        margin-top: 16px;
        max-width: 100%;
        min-width: 100%;
    }
}

@media screen and (min-width: 769px) {
    .desktop_report_card {
        display: flex !important;
    }

    .mobile_report_card {
        display: none !important;
    }
}


@media screen and (min-width: 768px) and (max-width: 1200px) {
    .main_container {
        overflow: scroll !important;
    }
}