@import "./buttons.css";
@import "./sidebar.css";
@import "./header.css";
@import "./footer.css";
@import "./selector.css";
@import "./report.css";
@import "./loader.css";


/* Signin */
._container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: 100%;
    width: 100%;
}

.background_banner, .home_background_banner {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #000;
}

.background_banner {
    min-height: 100vh;
    max-height: 100vh;
}

.background_banner video {
    height: inherit !important;
}

.home_background_banner {
    min-height: 438px;
}

.background_banner ._after,
.home_background_banner ._after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
}

.tiled {
    position: absolute;
    bottom: -1px;
    right: -1px;
    border-left: 100vw solid transparent !important;
    border-bottom: 160px solid #ffffff;
    z-index: 1;
}

.banner_image,
.login_banner_background_image,
.home_background_banner_image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    height: 100%;
    width: 100%;
}

.login_banner_background_image {
    background-image: url(https://images.unsplash.com/photo-1564769662533-4f00a87b4056?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1544&q=80);
}

.social_login_container_size {
    margin: 120px 0 0;
}

.login_form_container {
    border-radius: 8px;
    width: 425px !important;
    height: 400px;
}

.login_round_box {
    border-radius: 60px;
    margin: 6px 0;
    height: 54px;
    width: 320px;
    padding: 0 0 0 64px;
}

.google_button button {
    border-radius: 60px;
    margin: 6px 0;
    height: 54px;
    padding: 0 0 0 64px;
}

.login_round_box p {
    text-transform: uppercase;
    margin-left: 12px;
    font-weight: 500;
    letter-spacing: 0.55px;
    cursor: pointer;
}

/* Home Page */
.home_background_banner_image {
    background-image: url(https://wild-blog-strapi.s3.amazonaws.com/wild_wiki_68e40464b2.jpg);
}

.main_content_area {
    padding: 64px 0 120px;
    /* background: #f1ebeb40; */
}

/* Athlete */
.placeholder_name_box {
    position: relative;
    height: 50px;
    min-width: 50px;
    border-radius: 50%;
    background-color: #000000;
}

.placeholder_name_box .name_letter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 1px;
}

/* Form */
.form_container {
    position: relative;
    width: 100%;
    margin: 0;
}

.form_container .label {
    font-size: 26px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 1.44068px;
    text-transform: uppercase;
    text-align: center;
    color: #010101;
    margin: 24px 0;
}

.input_form {
    max-width: 320px;
    margin: 24px 0;
    width: 100%;
}


.black_alert_icon>svg {
    height: 56px;
    width: 56px;
}


/* Loader */
.progress_loader {
    width: 320px;
    margin: 0 auto;
    border-radius: 16px;
    border: 2px solid #27AE60;
    position: relative;
    padding: 6px;
}

.progress_loader:before {
    content: '';
    border: 1px solid #27AE60;
    border-radius: 16px;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
}

.progress_loader .loaderBar {
    position: absolute;
    border-radius: 16px;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: #27AE60;
    width: 0;
    animation: borealisBar 1s linear infinite;
}

.flex_container {
    width: calc(100%/4);
    height: 250px;
    margin: 0 8px;
}

.gridContainer {
    margin: 24px 0px 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10px;
}

.coaches_flex_container {
    margin: 0 12px;
    border-radius: 16px;
    background: linear-gradient(#FFE895, #A4D587);
    box-shadow: rgb(0 0 0 / 35%) 0px 10px 15px 0px;
    min-height: 375px;
}

.absolute_card {
    background: #00000085;
    height: 100%;
    width: 100%;
    border-radius: 16px;
    padding: 0 0 16px 16px;
    box-shadow: rgb(0 0 0 / 35%) 0px 10px 15px 0px;
}

.absolute_card p, .absolute_card h4, .absolute_card h2 {
    color: #FFFFFF;
}

.coach_container {
    padding: 32px;
}

@keyframes borealisBar {
    0% {
        left: 0%;
        right: 100%;
        width: 0%;
    }

    50% {
        right: 0%;
        left: 50%;
        width: 50%;
    }

    100% {
        left: 100%;
        right: 0%;
        width: 0%;
    }
}

@media screen and (max-width: 992px) {
    .social_login_container_size {
        width: 96%;
    }

    .login_round_box {
        padding: 0;
        justify-content: center;
    }

    .content_area {
        max-width: 96%;
        z-index: 100;
    }

    .coach_container {
        padding: 16px 0px;
    }

    .absolute_card {
        border-radius: 24px;
    }
}

@media screen and (max-width: 576px) {
    .input_form {
        max-width: 85%;
    }

    .login_form_container {
        width: 100% !important;
    }

    .home_background_banner {
        min-height: 200px;
    }

    .home_background_banner {
        background: #fff;
        color: #000 !important;
    }
}

@media screen and (min-width: 1600px) {
    .content_area {
        max-width: 1520px;
    }

    .coaches_flex_container {
        min-width: 356px;
        max-width: 360px;
    }

    .coaches_flex_container img {
        height: 280px;
        width: 100%;
    }
}

@media screen and (min-width: 1360px) and (max-width: 1599px) {
    .content_area {
        max-width: 1280px;
    }

    .coaches_flex_container {
        min-width: 299px;
        max-width: 300px;
    }

    .coaches_flex_container img {
        height: 280px;
        width: 100%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1360px) {
    .content_area {
        max-width: 1120px;
    }

    .coaches_flex_container {
        min-width: 320px;
        max-width: 320px;
    }

    .coaches_flex_container img {
        height: 280px;
        width: 100%;
    }
}

.lock_sidebar_at_top_left {
    position: fixed;
    top: 16px;
}

@media screen and (min-width: 993px) and (max-width: 1199px) {
    .content_area {
        max-width: 975px;
    }

    .flex_container {
        width: calc(100%/2);
    }

    .coaches_flex_container {
        min-width: 280px;
        max-width: 280px;
    }

    .coaches_flex_container img {
        height: 280px;
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .flex_container, .coaches_flex_container {
        width: 98%;
        margin: 8px;
    }

    .coaches_flex_container {
        min-width: 90%;
        height: 300px;
        margin: 12px 16px;
        border-radius: 24px;
    }

    .coaches_flex_container img {
        height: 260px;
        min-width: 260px;
    }
}

/* Loader */
.progress_loader {
    width: 320px;
    margin: 0 auto;
    border-radius: 16px;
    border: 2px solid #27AE60;
    position: relative;
    padding: 6px;
}

.progress_loader:before {
    content: '';
    border: 1px solid #27AE60;
    border-radius: 16px;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
}

.progress_loader .loaderBar {
    position: absolute;
    border-radius: 16px;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: #27AE60;
    width: 0;
    animation: borealisBar 1s linear infinite;
}

@keyframes borealisBar {
    0% {
        left: 0%;
        right: 100%;
        width: 0%;
    }

    50% {
        right: 0%;
        left: 50%;
        width: 50%;
    }

    100% {
        left: 100%;
        right: 0%;
        width: 0%;
    }
}

/* Typeform model popup */
.typeform_model_popup,
.model_popup {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.typeform_model_popup .popup_card {
    background: #000000;
    border-radius: 12px;
    padding: 16px;
    margin: auto;
}

.model_popup .popup_card {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 80px 32px 32px;
    margin: auto;
    width: 650px;
    height: 600px;
}


@media screen and (max-width: 768px) {
    .model_popup .popup_card {
        padding: 80px 32px 32px;
        width: 95%;
        height: 90%;
        max-height: 830px;
        overflow: scroll;
    }
}

/* View more or less */
.view_more_text {
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 8px;
}

.view_less_text {
    text-transform: uppercase;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    margin-left: 8px;
}

.view_more_text:hover {
    text-decoration: underline;
    cursor: pointer;
}

/* Shop product card */
.product_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 992px) {
    .product_card {
        width: 96%;
    }
}