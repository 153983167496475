/* Active Button */
.black-button {
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    background-color: transparent;
    text-align: center;
    color: #ffffff !important;
    background-color: #000000;
    border-radius: 60px;
    height: 56px;
    letter-spacing: 2px;
    width: 100%;
    box-shadow: none !important;
    text-transform: uppercase;
    cursor: pointer !important;
    text-decoration: none;
    -webkit-filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important;
            filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important;
}

.white-button-with-border {
    width: 100%;
    display: inline-block;
    position: relative;
    z-index: 1;
    border: 1px solid #010101;
    background-color: transparent;
    text-align: center;
    color: #010101 !important;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 2px;
    height: 46px;
    width: 100%;
    box-shadow: none !important;
    cursor: pointer !important;
    padding: 15.5px 0;
    border-radius: 25px;
    text-transform: uppercase;
    -webkit-filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important;
            filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important;
}

/* Disable Button */
.black-button-disable {
    border: 0px;
    display: inline-block;
    position: relative;
    z-index: 1;
    background-color: #c1c1c1 !important;
    text-align: center;
    color: rgba(0, 0, 0, 0.2) !important;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 2px;
    height: 46px;
    width: 100%;
    padding: 15.5px 0;
    border-radius: 25px;
    text-transform: uppercase;
    -webkit-filter: drop-shadow(0px 0.5px 4px rgba(0, 0, 0, 0.12)) !important;
            filter: drop-shadow(0px 0.5px 4px rgba(0, 0, 0, 0.12)) !important;
}

.black-button p,
.black-button-disable p,
.white-button-with-border p {
    line-height: 120% !important;
    font-size: 12px !important;
}

.black-button-disable p {
    cursor: not-allowed !important;
}

.black-button:focus::after,
.white-button-with-border:focus::after,
.black-button-disable:focus::after {
    background: #e5e5e5;
    outline: none;
    box-shadow: inset 0px 0px 5px #c1c1c1;
    box-shadow: 0 0 0 #e5e5e5;
}

.black-button:hover p,
.black-button-disable:hover p {
    color: rgba(255, 255, 255, 0.7) !important;
}


.white-button-with-border:hover p {
    color: rgba(0, 0, 0, 0.5) !important;
}
.sidebar {
    flex: 0 0 280px !important;
    height: 100vh;
    max-width: 280px !important;
    position: fixed;
    width: 100%;
    background-color: #010101;
}

.logo-with-team-name {
    margin: 60px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menus-container {
    margin-top: 106px;
}

.sidebar-icon {
    width: 28px;
    height: 28px;
}

.logout-icon {
    width: 24px;
    height: 24px;
}

.sidebar-icon {
    margin-right: 16px;
}

.logout-icon {
    margin-right: 20px;
}

.logo-with-team-name .title {
    margin: 10px 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #ffffff;
}

.menu-item {
    margin: 0 0 16px;
    padding-left: 56px;
    height: 24px;
    display: flex;
    align-items: center;
}

.nav-link {
    font-size: 18px;
    line-height: 150%;
    color: rgba(255, 255, 255, 0.7) !important;
    display: flex;
    align-items: center;
}

.menu-item.active .nav-link {
    color: rgba(255, 255, 255, 1) !important;
}

.menu-item .nav-link:hover {
    color: rgba(255, 255, 255, 1) !important;
}

.bottom-navs {
    position: absolute;
    bottom: 3em;
}

.logout {
    margin: 0;
    height: 48px;
    padding-left: 56px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logout .nav-link:hover {
    color: rgba(255, 255, 255, 1) !important;
}


@media screen and (max-height: 600px) {
    .menus-container {
      margin-top: 25% !important;
    }
  }
#header {
    -ms-flex-pack: justify;
    align-items: center;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    height: 180px;
}

#header>div {
    max-width: 90%;
}

#header a,
#header p {
    text-decoration: none;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 2px;
    font-weight: normal;
    text-transform: uppercase;
}

#header .logo {
    cursor: pointer;
}


@media screen and (max-width: 576px) {
    #header {
        height: 108px;
    }

    #header>div {
        max-width: 90%;
    }

    #header p {
        font-size: 14px !important;
    }
}
.footer {
    width: 100%;
    height: 100%;
    background: #010101;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer a {
    text-decoration: none;
    cursor: pointer;
    color: #fff;
    font-size: 17px;
    line-height: 150%;
    font-weight: normal;
}

.footer_row {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 1100px;
    width: 100%;
}

.left ul,
.icons {
    align-items: center;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    width: 100%;
}

.icons {
    justify-content: center;
    align-items: center;
    margin: 24px 0 0;
}

.left ul li {
    padding: 0 16px;
}

.icons a {
    padding: 0 16px;
}

.left ul li:first-child,
.icons a:first-child {
    padding-left: 0px !important;
}

.left ul li:last-child,
.icons a:last-child {
    padding-right: 0px !important;
}

.right {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.copyrights {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1100px;
    margin: 32px 0 0;
}

.copyrights p {
    margin: 0;
    color: #ffffff;
    font-size: 14px;
    line-height: 150%;
    word-spacing: 2px;
}

.help_box {
    display: flex;
    flex-direction: column;
    background: #000000;
    width: 100%;
    max-width: 352px;
    color: #ffffff;
    text-align: center;
    margin: 0px 0 64px;

}

.help_icon_box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}

.help_icon_box h3 {
    margin-left: 10px;
}

.help_box span a {
    margin-left: 6px;
    text-decoration: underline !important;
}

.footer a::selection,
.footer p::selection,
.footer h3::selection {
    background: #ffffff !important;
    color: #010101 !important;
}

@media screen and (min-width: 992px) {
    .show_for_desktop {
        display: flex;
    }

    .show_for_mobile {
        display: none;
    }

    .footer {
        padding: 60px 0 120px;
    }
}


@media screen and (max-width: 992px) {
    .show_for_desktop {
        display: none;
    }

    .help_box {
        margin: 60px 0;
    }

    .show_for_mobile {
        display: flex;
    }

    .footer_row {
        justify-content: center;
        height: 100%;
        padding: 40px 0;
    }

    .footer {
        padding-bottom: 80px;
    }

    .copyrights {
        text-align: center;
        max-width: 437px;
        width: 90%;
    }
}
.team_selector,
.date_selector,
.report_date_selector {
  position: relative;
  height: 45px;
  min-width: 204px;
  max-width: 206px;
}

.report_date_selector,
.report_date_selector .listing {
  min-width: 260px;
  max-width: 264px;
}

.team_selector {
  min-width: 204px;
  max-width: 206px;
}

.date_selector {
  min-width: 182px;
  max-width: 184px;
}

.report_date_selector .teams-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.report_date_selector .teams-list::-webkit-scrollbar {
  width: 8px;
  background-color: #F5F5F5;
}

.report_date_selector .teams-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.selectory_arrow_component {
  margin-top: 6px;
}

.report_date_selector>p {
  font-size: 16px;
  line-height: 120%;
  margin-right: 8px;
}

.report_date_selector .teams-list {
  min-width: 244px;
  max-width: 250px;
}

.selectory_arrow_component img,
.date_selector img,
.report_date_selector img {
  cursor: pointer;
}

.pointer_not_allowed {
  cursor: not-allowed !important;
}

.selected_team {
  font-style: normal;
  font-size: 24px;
  line-height: 120%;
  color: #000000;
  margin: 0;
  cursor: pointer;
  text-align: start !important;
}

.listing {
  position: absolute;
  left: 0;
  top: 45px;
  border-radius: 10px;
  z-index: 99;
  padding: 12px;
  width: 100%;
  min-width: 204px;
  max-width: 206px;
  background: white;
  box-shadow: 1px 2px 8px #01010160;
}

.teams-list {
  min-width: 204px;
  max-width: 206px;
  width: 100%;
  border-radius: 8px;
  height: 100%;
  max-height: 160px;
  overflow-y: auto;
}

.team-names {
  color: #01010180 !important;
  cursor: pointer !important;
  font-size: 16px;
  line-height: 120%;
  padding: 12px 0 0;
}

.team-names.active,
.team-names:hover {
  color: #010101 !important;
}

/* Sorting arrows */
.sorting_arrows {
  margin-top: -6px;
  margin-left: 3px;
}

.sorting_arrows span {
  height: 8px;
  width: 8px;
  cursor: pointer;
}
.main_container {
    height: 100%;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 40px;
    margin: auto;
    max-width: 1560px !important;
    z-index: 100;
}

@media print {
    body {
        overflow-y: hidden;
        /* Hide vertical scrollbar */
        overflow-x: hidden;
        /* Hide horizontal scrollbar */
    }

    .main_container {
        background: #fff;
        -webkit-print-color-adjust: exact;
    }
}


/* Header */

.title {
    color: #000000;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
}

.cycle_date {
    margin-top: 8px;
}

.seperator {
    margin: 0 8px !important;
}

/* Common Header */
.common_label {
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin: 32px 0 0;
    width: 100%;
}

.common_label_with_underline {
    font-size: 20px;
    line-height: 28px;
    color: #000000;
    margin: 32px 0 0;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid #000000;
}

.common_subtitle_without_underline {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding: 20px 0 16px;
}

.common_description {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding: 16px 0;
}

.common_subtitle {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    width: 90%;
    padding: 16px 0;
}

/* Cycle Checkins */
.bullet_container {
    margin: 0 16px;
    min-width: 260px;
    max-width: 300px;
}

.bullet_container:first-child {
    margin-left: 0px;
}

.cycle_days {
    font-size: 28px;
    line-height: 28px;
    font-weight: 900;
}

.trend_value {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-top: -20px;
    margin-left: 4px;
}

.bullet {
    font-size: 14px;
    line-height: 20px;
    margin: 10px 0 0;
}

.scrollable {
    overflow: scroll !important;
}

.cycle_bullets_card {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 32px 0 48px;
}

.report_download_button_text {
    margin: -4px 0 0;
    color: "#ffffff";
    font-size: 12px !important;
    line-height: 120% !important;
}

.morning_header {
    background: #F4F4F4;
    border-radius: 6px;
    padding: 12px;
    max-width: 425px;
    min-width: 426px;
}

.tooltip {
    position: absolute;
    left: 0;
    bottom: 0;
    color: #000000;
    text-align: center;
    z-index: 100;
    border-radius: 4px;
    width: 48px;
    height: 24px;
    margin-left: -12px;
}


.tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000000 transparent transparent transparent;
}

.mtb_32 {
    margin: 32px 0;
}

.grey_box {
    background: #F4F4F4;
    border-radius: 4px;
    padding: 12px;
}

@media screen and (max-width: 768px) {

    .main_container {
        padding: 48px 24px;
    }

    .desktop_report_card {
        display: none !important;
    }

    .mtb_32 {
        margin: 16px 0;
    }

    .cycle_bullets_card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin: 16px 0 48px;
    }

    .bullet_container {
        margin: 16px 0;
        max-width: 100%;
    }

    .mobile_report_card {
        display: flex !important;
    }

    .title {
        font-size: 18px;
    }

    .cycle_date {
        margin-top: 0px;
    }

    .morning_checkin_header {
        flex-direction: column;
    }

    .morning_header {
        margin-top: 16px;
        max-width: 100%;
        min-width: 100%;
    }
}

@media screen and (min-width: 769px) {
    .desktop_report_card {
        display: flex !important;
    }

    .mobile_report_card {
        display: none !important;
    }
}


@media screen and (min-width: 768px) and (max-width: 1200px) {
    .main_container {
        overflow: scroll !important;
    }
}
.reports_loading_container {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 10000;
    background: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 100%;
}

.pdf_downloading_container {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 10000;
    background: rgba(255, 255, 255, 1);
    height: 100%;
    width: 100%;

}

.custom_loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #010101;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 0.5s linear infinite;
            animation: spin 0.5s linear infinite;
}

.custom_placeholder_loader {
    border: 6px solid #cdc8c8;
    border-top: 6px solid #010101;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 0.5s linear infinite;
            animation: spin 0.5s linear infinite;
}

.screen_custom_loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #010101;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    -webkit-animation: spin 0.5s linear infinite;
            animation: spin 0.5s linear infinite;
}

.login_card_loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #010101;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    -webkit-animation: spin 0.5s linear infinite;
            animation: spin 0.5s linear infinite;
}

.custom_small_loader {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #010101;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    -webkit-animation: spin 0.5s linear infinite;
            animation: spin 0.5s linear infinite;
}

.custom_small_downloading_loader {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #010101;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    -webkit-animation: spin 0.5s linear infinite;
            animation: spin 0.5s linear infinite;
}

.custom_search_loader {
    border: 3px solid #cecece;
    border-top: 3px solid #010101;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 16px;
    top: 6px;
    z-index: 100;
    margin-top: 0.3rem;
    -webkit-animation: spin 0.5s linear infinite;
            animation: spin 0.5s linear infinite;
}

.custom_button_loader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #010101;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 0.5s linear infinite;
            animation: spin 0.5s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
/* Signin */
._container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    height: 100%;
    width: 100%;
}

.background_banner, .home_background_banner {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #000;
}

.background_banner {
    min-height: 100vh;
    max-height: 100vh;
}

.background_banner video {
    height: inherit !important;
}

.home_background_banner {
    min-height: 438px;
}

.background_banner ._after,
.home_background_banner ._after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .7);
}

.tiled {
    position: absolute;
    bottom: -1px;
    right: -1px;
    border-left: 100vw solid transparent !important;
    border-bottom: 160px solid #ffffff;
    z-index: 1;
}

.banner_image,
.login_banner_background_image,
.home_background_banner_image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    height: 100%;
    width: 100%;
}

.login_banner_background_image {
    background-image: url(https://images.unsplash.com/photo-1564769662533-4f00a87b4056?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1544&q=80);
}

.social_login_container_size {
    margin: 120px 0 0;
}

.login_form_container {
    border-radius: 8px;
    width: 425px !important;
    height: 400px;
}

.login_round_box {
    border-radius: 60px;
    margin: 6px 0;
    height: 54px;
    width: 320px;
    padding: 0 0 0 64px;
}

.google_button button {
    border-radius: 60px;
    margin: 6px 0;
    height: 54px;
    padding: 0 0 0 64px;
}

.login_round_box p {
    text-transform: uppercase;
    margin-left: 12px;
    font-weight: 500;
    letter-spacing: 0.55px;
    cursor: pointer;
}

/* Home Page */
.home_background_banner_image {
    background-image: url(https://wild-blog-strapi.s3.amazonaws.com/wild_wiki_68e40464b2.jpg);
}

.main_content_area {
    padding: 64px 0 120px;
    /* background: #f1ebeb40; */
}

/* Athlete */
.placeholder_name_box {
    position: relative;
    height: 50px;
    min-width: 50px;
    border-radius: 50%;
    background-color: #000000;
}

.placeholder_name_box .name_letter {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #ffffff;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 1px;
}

/* Form */
.form_container {
    position: relative;
    width: 100%;
    margin: 0;
}

.form_container .label {
    font-size: 26px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 1.44068px;
    text-transform: uppercase;
    text-align: center;
    color: #010101;
    margin: 24px 0;
}

.input_form {
    max-width: 320px;
    margin: 24px 0;
    width: 100%;
}


.black_alert_icon>svg {
    height: 56px;
    width: 56px;
}


/* Loader */
.progress_loader {
    width: 320px;
    margin: 0 auto;
    border-radius: 16px;
    border: 2px solid #27AE60;
    position: relative;
    padding: 6px;
}

.progress_loader:before {
    content: '';
    border: 1px solid #27AE60;
    border-radius: 16px;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
}

.progress_loader .loaderBar {
    position: absolute;
    border-radius: 16px;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: #27AE60;
    width: 0;
    -webkit-animation: borealisBar 1s linear infinite;
            animation: borealisBar 1s linear infinite;
}

.flex_container {
    width: calc(100%/4);
    height: 250px;
    margin: 0 8px;
}

.gridContainer {
    margin: 24px 0px 0px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10px;
}

.coaches_flex_container {
    margin: 0 12px;
    border-radius: 16px;
    background: linear-gradient(#FFE895, #A4D587);
    box-shadow: rgb(0 0 0 / 35%) 0px 10px 15px 0px;
    min-height: 375px;
}

.absolute_card {
    background: #00000085;
    height: 100%;
    width: 100%;
    border-radius: 16px;
    padding: 0 0 16px 16px;
    box-shadow: rgb(0 0 0 / 35%) 0px 10px 15px 0px;
}

.absolute_card p, .absolute_card h4, .absolute_card h2 {
    color: #FFFFFF;
}

.coach_container {
    padding: 32px;
}

@-webkit-keyframes borealisBar {
    0% {
        left: 0%;
        right: 100%;
        width: 0%;
    }

    50% {
        right: 0%;
        left: 50%;
        width: 50%;
    }

    100% {
        left: 100%;
        right: 0%;
        width: 0%;
    }
}

@keyframes borealisBar {
    0% {
        left: 0%;
        right: 100%;
        width: 0%;
    }

    50% {
        right: 0%;
        left: 50%;
        width: 50%;
    }

    100% {
        left: 100%;
        right: 0%;
        width: 0%;
    }
}

@media screen and (max-width: 992px) {
    .social_login_container_size {
        width: 96%;
    }

    .login_round_box {
        padding: 0;
        justify-content: center;
    }

    .content_area {
        max-width: 96%;
        z-index: 100;
    }

    .coach_container {
        padding: 16px 0px;
    }

    .absolute_card {
        border-radius: 24px;
    }
}

@media screen and (max-width: 576px) {
    .input_form {
        max-width: 85%;
    }

    .login_form_container {
        width: 100% !important;
    }

    .home_background_banner {
        min-height: 200px;
    }

    .home_background_banner {
        background: #fff;
        color: #000 !important;
    }
}

@media screen and (min-width: 1600px) {
    .content_area {
        max-width: 1520px;
    }

    .coaches_flex_container {
        min-width: 356px;
        max-width: 360px;
    }

    .coaches_flex_container img {
        height: 280px;
        width: 100%;
    }
}

@media screen and (min-width: 1360px) and (max-width: 1599px) {
    .content_area {
        max-width: 1280px;
    }

    .coaches_flex_container {
        min-width: 299px;
        max-width: 300px;
    }

    .coaches_flex_container img {
        height: 280px;
        width: 100%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1360px) {
    .content_area {
        max-width: 1120px;
    }

    .coaches_flex_container {
        min-width: 320px;
        max-width: 320px;
    }

    .coaches_flex_container img {
        height: 280px;
        width: 100%;
    }
}

.lock_sidebar_at_top_left {
    position: fixed;
    top: 16px;
}

@media screen and (min-width: 993px) and (max-width: 1199px) {
    .content_area {
        max-width: 975px;
    }

    .flex_container {
        width: calc(100%/2);
    }

    .coaches_flex_container {
        min-width: 280px;
        max-width: 280px;
    }

    .coaches_flex_container img {
        height: 280px;
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .flex_container, .coaches_flex_container {
        width: 98%;
        margin: 8px;
    }

    .coaches_flex_container {
        min-width: 90%;
        height: 300px;
        margin: 12px 16px;
        border-radius: 24px;
    }

    .coaches_flex_container img {
        height: 260px;
        min-width: 260px;
    }
}

/* Loader */
.progress_loader {
    width: 320px;
    margin: 0 auto;
    border-radius: 16px;
    border: 2px solid #27AE60;
    position: relative;
    padding: 6px;
}

.progress_loader:before {
    content: '';
    border: 1px solid #27AE60;
    border-radius: 16px;
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
}

.progress_loader .loaderBar {
    position: absolute;
    border-radius: 16px;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    background: #27AE60;
    width: 0;
    -webkit-animation: borealisBar 1s linear infinite;
            animation: borealisBar 1s linear infinite;
}

@keyframes borealisBar {
    0% {
        left: 0%;
        right: 100%;
        width: 0%;
    }

    50% {
        right: 0%;
        left: 50%;
        width: 50%;
    }

    100% {
        left: 100%;
        right: 0%;
        width: 0%;
    }
}

/* Typeform model popup */
.typeform_model_popup,
.model_popup {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.typeform_model_popup .popup_card {
    background: #000000;
    border-radius: 12px;
    padding: 16px;
    margin: auto;
}

.model_popup .popup_card {
    background: #FFFFFF;
    border-radius: 12px;
    padding: 80px 32px 32px;
    margin: auto;
    width: 650px;
    height: 600px;
}


@media screen and (max-width: 768px) {
    .model_popup .popup_card {
        padding: 80px 32px 32px;
        width: 95%;
        height: 90%;
        max-height: 830px;
        overflow: scroll;
    }
}

/* View more or less */
.view_more_text {
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 8px;
}

.view_less_text {
    text-transform: uppercase;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    margin-left: 8px;
}

.view_more_text:hover {
    text-decoration: underline;
    cursor: pointer;
}

/* Shop product card */
.product_card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width: 992px) {
    .product_card {
        width: 96%;
    }
}
.content {
  padding: 56px 56px 32px 56px;
  min-height: 100vh;
  width: calc(100% - 280px);
  margin-left: 280px;
  position: relative;
  overflow: auto;
  /* border: 4px solid #27AE60; */
}

@media (min-width: 992px) and (max-width: 1280px) {
  .content {
    padding: 56px 32px 32px;
  }
}

/* Manage Team, No Athletes, No Coaches Styles */

.no-record-found {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 223px);
}

.no-record-found>div {
  display: flex;
}

@media screen and (max-width: 1366px) {
  .no-record-found .no-record-img {
    max-width: 440px !important;
    margin: 24px 0 0;
  }
}

.no-record-found .no-record-img {
  max-width: 492px;
}

.no-record-found .message-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.no-record-found p {
  color: #010101;
  text-align: center;
  margin: 30px 0;
  max-width: 382px;
}


/* Help Section */
.help_container {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 100px;
  width: 100%;
  height: 100%;
  max-width: 592px;
  overflow-y: auto;
  padding: 56px 48px;
  background: #F7F7F7;
  box-shadow: 0px 25px 47px rgba(0, 0, 0, 0.3);
}

/* 1- Team Selector and Selected Team Name Style */

.selected-option,
.main-title {
  font-style: normal;
  font-size: 24px;
  line-height: 120%;
  color: #000000;
  margin: 0;
  cursor: pointer;
  text-align: start !important;
}

.main-decription {
  margin: 20px 0 0;
  color: #2A2A2A !important;
  font-size: 16px;
  line-height: 140%;
  text-align: start !important;
}

.invite-decription {
  margin: 20px 0 0;
  color: #2A2A2A !important;
  font-size: 16px;
  line-height: 140%;
  text-align: center !important;
}

.small-decription {
  color: #2A2A2A !important;
  margin: 0 0 0 12px;
  font-size: 13px;
  line-height: 130%;
  text-align: start !important;
}

.small-decription-disabled {
  color: rgba(1, 1, 1, 0.4) !important;
  margin: 0 0 0 12px;
  font-size: 13px;
  line-height: 130%;
  text-align: start !important;
}

.selected-option {
  margin-right: 1rem !important;
}

/* 2- Search Bar Styles */

span[aria-label="search"] svg {
  height: 18px;
  width: 18px;
  color: #01010140 !important;
}

.help-search span[aria-label="search"] svg {
  height: 18px;
  width: 18px;
  color: #010101 !important;
}

.help-search ::-webkit-input-placeholder {
  color: #010101 !important;
}

.help-search ::placeholder {
  color: #010101 !important;
}

.search-field {
  display: flex;
  width: 100%;
  height: 45px;
  position: relative;
}

.change-password-link {
  background: none;
}

.search-field input,
.change-password-link {
  font-size: 15px;
  line-height: 150%;
  color: #010101 !important;
}

input[type="checkbox"]:checked:before {
  border: #000000;
  background-color: #000000;
}

/* 3- Calendar Styles */

.date {
  color: rgba(1, 1, 1, 0.8);
  margin: 8px 0 0 0;
  font-size: 15px;
  line-height: 150%;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.short-names {
  text-align: center;
  font-size: 14px;
  line-height: 120%;
  color: rgba(1, 1, 1, 0.4);
  margin: 0px;
  letter-spacing: 2px;
  text-transform: uppercase;
  cursor: pointer;
}

.month-view {
  font-size: 17px;
  line-height: 120%;
  text-align: center;
  color: #010101;
  margin: 0px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.calendar-table {
  margin: 0 auto;
  width: 312px !important;
  border-collapse: collapse;
  border-spacing: 0;
}

.calendar-table .today {
  background-color: #010101;
  border-radius: 25px;
  color: #fff !important;
}

.calendar-table th {
  border: 1px solid #01010140;
  color: #01010140 !important;
  border-width: 0 0 1px;
  font-size: 11.2244px !important;
  line-height: 150% !important;
  font-weight: normal !important;
}

.calendar-table td {
  border: none !important;
  border-width: 0;
  color: #010101 !important;
  font-size: 17px !important;
  line-height: 150% !important;
  font-weight: normal !important;
}

.calendar-table td,
.calendar-table th {
  padding: .55em 0.4em !important;
  height: 34px !important;
  width: 34px !important;
  text-align: center !important;
}

.calendar-table td:hover {
  background-color: #010101;
  border-radius: 25px;
  color: #fff !important;
  cursor: pointer !important;
}

.calendar-table .in-prev-month,
.calendar-table .in-next-month {
  opacity: 0.4;
}

/* 4- Help Card Styles */

/* 5- Three Dots Styles */

.dots-listing {
  position: absolute;
  right: 0;
  border-radius: 10px;
  z-index: 10;
  width: 120px !important;
  margin: 32px 6px 0 0 !important;
  padding: 12px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 1px 2px 8px #01010160;
}

.dot-item-names {
  color: #01010180 !important;
  cursor: pointer !important;
  font-size: 16px;
  line-height: 120%;
  padding: 12px 0 0;
}

.dot-item-names.active,
.dot-item-names:hover {
  color: #010101 !important;
}

/* Table Styles */

table {
  box-sizing: border-box !important;
  border-spacing: 0 1rem !important;
}

.athlete_list_item {
  z-index: 1;
  border-radius: 10px;
  cursor: pointer;
  padding: 0 12px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.10);
  -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.10);
  -moz-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.10);
  transition: box-shadow 300ms ease-in-out;
  /* height: 90px; */
  min-height: 90px;
  background: #ffffff;
}

.athlete-name,
.coach-name {
  text-transform: capitalize;
}


.athlete-name,
.training_plan_name,
.sport_type,
.created_date,
.reviewed_status,
.draft_status,
.published_status,
.view_count,
.purchase_count,
.athlete-status,
.athlete-cycle-phase,
.athlete-cycle-length,
.athlete-readiness,
.athlete-next-training,
.athlete-email,
.athlete-member-since,
.athlete-checkins,
.coach-name,
.coach-email,
.coach-member-since,
.coach-status,
.sport_type,
.published_status,
.views_count,
.plan_purchases,
.plan_athletes_count,
.plan_video_link {
  font-size: 14px !important;
  line-height: 140%;
  text-align: left;
  width: 100%;
}

.athlete-cycle-length,
.athlete-readiness,
.athlete-next-training {
  margin-left: 12px;
}

.athlete-checkins {
  margin-left: 32px;
}

.video_link {
  font-size: 12px !important;
  line-height: 120%;
  color: #034cf4 !important;
  text-align: left !important;
}

.custom_tag_style {
  padding: 6px 8px;
  border-radius: 6px;
  width: auto;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  color: #2A2A2A;
  font-style: normal;
  font-weight: normal;
  list-style: none;
  margin: 8px 4px 0 0;
}

.custom_tag_style .tag-title {
  font-size: 12px;
  line-height: 120%;
}

.sport_type,
.created_date,
.reviewed_status,
.draft_status,
.video_link,
.published_status,
.view_count,
.purchase_count,
.athlete-cycle-phase,
.athlete-cycle-length,
.athlete-readiness,
.athlete-next-training,
.athlete-checkins,
.athlete-member-since,
.coach-member-since,
.sport_type,
.published_status,
.views_count,
.plan_purchases,
.plan_athletes_count {
  text-align: left !important;
}

.athlete-name,
.athlete-cycle-phase,
.athlete-readiness,
.athlete-member-since,
.coach-name,
.coach-member-since,
.athlete-checkins {
  color: #010101;
  text-align: left;
}

.athlete-email,
.coach-email {
  color: rgba(0, 0, 0, 0.66);
}

.athlete-status,
.athlete-cycle-length,
.athlete-next-training,
.coach-status {
  color: rgba(0, 0, 0, 0.66);
}






.tagInput {
  background-color: #ffffff !important;
  color: #151515 !important;
}

.ant-menu-item-selected {
  background-color: none !important;
}

.ant-menu-item-active {
  background-color: #010101 !important;
}

.ant-card-body {
  width: 100% !important;
  display: flex !important;
}

.ant-input-affix-wrapper.ant-input-password>.ant-input#password {
  background-color: #151515 !important;
  color: #FFFFFF;
}

.ant-input-affix-wrapper.ant-input-password>.ant-input#confirmPassword {
  background-color: #151515 !important;
  color: #FFFFFF;
}

.ant-btn:hover,
.ant-btn:active,
.ant-btn:focus {
  color: #000000;
}

.ant-modal-content {
  border-radius: 18px !important;
}


.active svg {
  color: #151515 !important;
}

/* Create Plans || Update Plans || Create Sessions || Update Sessions */
.fixed_header {
  height: 56px;
  position: fixed;
  top: 0px;
  left: 280px;
  width: calc(100% - 280px);
  background: #f7f7f7;
  z-index: 100;
  padding: 64px 0;
}

/* Settings Styles */

.settings-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 80px auto;
  width: 100%;
  max-width: 476px;
}

.update_password {
  margin: 0 auto !important;
}


.forgot-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  width: 100%;
  height: 100%;
  max-width: 425px;
}

.border-top {
  border-top: 0.35px solid rgba(1, 1, 1, 0.4);
}

.border-bottom {
  border-bottom: 0.35px solid rgba(1, 1, 1, 0.4);
}


.light_border_bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.8);
}

.border-bottom-red {
  border-bottom: 2px solid #EB5757;
}


.absolute_info {
  position: absolute;
  right: 19px;
  top: -8px;
  background: #8e8d8d;
  padding: 8px 12px;
  border-radius: 8px;
  max-width: 356px;
}

.absolute_info p {
  font-size: 12px;
  color: #ffffff;
}

.settings-form-item {
  width: 100%;
  margin: 0;
  padding: 12px 0;
  position: relative;
}

.settings-label {
  color: rgba(1, 1, 1, 0.4) !important;
  font-style: normal;
  font-size: 13px !important;
  line-height: 130% !important;
}

.settings-value {
  background: none;
  border: none;
  padding: 0;
  box-shadow: none;
  outline: none;
  margin: 0;
  font-family: inherit !important;
}

.settings-value,
.settings-form-item select,
.settings-value textarea {
  margin: 16px 0 0;
}

.settings-main input,
.settings-main input[type="password"],
.settings-main select,
.settings-main textarea {
  background: none !important;
  color: #010101;
  box-shadow: none;
  border: none;
  outline: none;
  padding: 0;
  font-size: 16.5px;
  line-height: 140%;
  font-style: normal;
  font-weight: normal;
}

.forget_password_input,
.forget_password_input:focus-visible {
  background: none !important;
  width: 100% !important;
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  outline: none !important;
  padding: 16px 8px;
  font-size: 16.5px;
  line-height: 140%;
  color: #010101 !important;
  font-style: normal;
  font-weight: normal;
}

.coach-types {
  border-radius: 5px;
  background: none !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
}



.disabledDiv {
  cursor: not-allowed !important;
  opacity: 0.4;
}

.disabledClick {
  pointer-events: none !important;
}

.hovering-card {
  background-color: rgb(255, 255, 255);
  min-width: 275px;
  min-height: 112px;
  height: auto;
  box-shadow: rgb(0 0 0 / 35%) 0px 8px 18px 0px;
  position: absolute;
  right: 0;
  margin-top: 24px;
  padding: 16px;
  border-radius: 12px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.hovering-card h3 {
  line-height: 28px;
  font-size: 18px;
  margin: 0 !important;
  font-weight: 900;
  color: #151515;
}

.hovering-card p {
  line-height: 20.24px;
  font-size: 16px;
  margin: 8px 0 0 !important;
  color: #151515;
}

.ant-select-selector {
  border: none !important;
  padding: 0px !important;
  margin-top: 1rem;
  line-height: 20.24px;
  font-size: 16px;
  font-weight: 900;
  color: #FFFFFF;
  text-transform: capitalize;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: none !important;
  margin: 0px !important;
  cursor: context-menu !important;
}

.btn-signup,
.sign-link {
  color: #010101 !important;
  font-size: 15px;
  line-height: 150%;
  margin: 0;
  padding: 4px 16px;
}

.btn-back {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background-color: #010101;
  color: #FFFFFF;
  font-size: 15px;
  line-height: 18.97px;
  letter-spacing: 2px;
  border-radius: 10px;
  height: 50px;
  padding: 0 24px;
  cursor: pointer;
  border: 2px solid #010101;
}

.athletes-profile-image,
.training_plan_image {
  min-width: 50px !important;
  max-width: 50px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  object-fit: cover !important;
  border-radius: 50% !important;
  aspect-ratio: 900 / 600 !important;
}

.ant-card-body {
  padding: 24px !important;
}

/** tags **/

.tags-input,
.plan_tags {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background: none !important;
  width: 100%;
  padding: 0 !important;
  border: none !important;
}

.tags-input {
  min-height: 148px;
}

.tags-input:focus-within,
.plan_tags:focus-within {
  border: none !important;
}

.emails-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100% !important;
  flex-wrap: wrap !important;
}

.tags-input input[aria-label="placeholder"],
.plan_tags input[aria-label="placeholder"] {
  color: rgba(1, 1, 1, 0.4) !important;
}

.tags-input input,
.plan_tags input {
  flex: 1 1;
  border: none;
  height: 46px;
  background: none !important;
  color: #000000 !important;
  font-size: 16px;
  line-height: 20px;
  margin: 12px 0 0 0 !important;
}

.tags-input input:focus,
.plan_tags input:focus {
  outline: transparent;
  border-color: #ffffff !important;
  box-shadow: none !important;
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
}

.tag,
.error-tag {
  padding: 10px 16px 10px 20px;
  background: #EBEBEB;
  border-radius: 5px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2A2A2A;
  font-style: normal;
  font-weight: normal;
  list-style: none;
  margin: 14px 14px 0 0;
}

.tag {
  background: #ffffff;
  color: #2A2A2A;
}

.error-tag {
  background: #ED5252;
  color: #ffffff;
}

.tag-title,
.error-tag-title {
  font-size: 16px;
  line-height: 140%;
}

.ant-select-selection-item {
  color: #010101 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 18.5px !important;
  line-height: 115% !important;
  text-transform: none !important;
}

.ant-select-item-option-content {
  font-size: 16px !important;
  line-height: 140% !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 567px) {

  .tags-input,
  .plan_tags {
    width: calc(100vw - 32px);
  }
}

/* Toasts */

.success-toast {
  background-color: #27AE60;
}

.error-toast {
  background-color: #EB5757;
}

.info-text {
  font-size: 16px;
  line-height: 20px;
  color: #FFFFFF;
  opacity: 0.5;
}

/* Management Screen Styles */

.management-tab-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 44px 0 0;
  z-index: 10;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

/* Gray box */

.left_widgets_area_640 {
  min-width: 640px;
}

.left_widgets_area_713 {
  min-width: 713px;
  width: 54%;
}

.right_widgets_area {
  margin-right: 32px;
  min-width: 292px;
  width: 25%;
}

.infoContainer {
  margin: 32px 0 0;
}

.grayContainer {
  margin: 12px 0;
}

.grayBox {
  background: #E5E5E5;
  padding: 16px 20px 16px 32px;
  min-width: 292px;
  width: 100%;
}

.grayBox {
  margin: 2px 0 0;
}

.grayBox .label {
  font-size: 12px;
  line-height: 150%;
  color: #000000;
  width: 116px;
}

.grayBox .alertsLabel {
  font-size: 12px;
  line-height: 150%;
  font-weight: 600;
  color: #000000;
  width: 180px;
}

.grayBox .subtitle {
  font-size: 12px;
  line-height: 150%;
  color: #010101;
  width: 170px;
}

.grayBox .alertsSubtitle {
  font-size: 12px;
  line-height: 150%;
  color: #010101;
  width: 180px;
  margin: 4px 0 0;
}

.grayBox h5 {
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  font-weight: 700;
}

.grayBox:first-child {
  margin-top: 0px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.grayBox:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.borderLeft {
  margin: 12px 0 0;
  border-left: 2px solid #000000;
  height: auto;
}

.blackCircle {
  width: 12px;
  height: 12px;
  background: #000000;
  border-radius: 50%;
  margin-right: 16px;
}

.left_widgets_area .titleBar {
  margin: 18px 0 0 -7px;
}

.trainingContainer {
  padding: 32px 0;
  margin-left: 26px;
}

.left_widgets_area .borderLeft .title {
  font-size: 16px;
  line-height: 140%;
  color: #000000;
}

.left_widgets_area .trainingContainer {
  background: #FFFFFF;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 16px 20px;
  margin-top: 14px;
  margin-left: 26px;
}

.left_widgets_area .trainingContainer p,
.left_widgets_area .trainingContainer ul {
  width: 200px;
}

.left_widgets_area .trainingContainer ul {
  margin-left: 32px;
}

.left_widgets_area .nonTrainingCards {
  width: 100%;
  min-width: 576px;
}

.left_widgets_area .trainingCards {
  width: 100%;
  min-width: 682px;
}

.intensityDurationGraph {
  width: 240px;
  margin-left: 48px;
  position: relative;
}

.intensity {
  font-size: 10px;
  line-height: 150%;
  position: absolute;
  left: -32px;
  top: 45%;
  color: #8F8F8F;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.duration {
  font-size: 10px;
  line-height: 150%;
  color: #8F8F8F;
  display: flex;
  margin-left: 8px;
  align-items: center;
}

.intensityDurationGraph .label {
  font-size: 10px;
  line-height: 150%;
  color: #8F8F8F;
}

.arrow {
  border: solid #8F8F8F;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* .circleDarkOutline {
  border: 1px solid #000000;
  padding: 2px;
  border-radius: 50%;
  margin: 4px auto 0;
}

.circleLightOutline {
  border: 1px solid #8F8F8F;
  padding: 2px;
  border-radius: 50%;
  margin: 4px auto 0;
} */

.emptyCircle {
  background: #C9C9C9;
  width: 52px;
  height: 6px;
  margin: 2px 0;
  border-radius: 2px;
}

.filledCircle {
  background: #000000;
  width: 52px;
  height: 6px;
  margin: 2px 0;
  border-radius: 2px;
}

/*  */

.blackTiled {
  position: absolute;
  bottom: -6px;
  margin-left: -13px;
  z-index: 1;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 18px solid black;
}

.phase {
  position: absolute;
  bottom: -28px;
  z-index: 1;
  width: -webkit-max-content !important;
  width: max-content !important;
  font-size: 12px;
  line-height: 150%;
  color: #000000;
}

.graphContainer,
.graphContainerForSingle {
  margin: 10px 0 24px;
  display: flex;
  flex-wrap: wrap;
  height: 88px;
  position: relative;
  border-left: 1px dashed #ACACAC;
  border-bottom: 1px solid #ACACAC;
}

.graphContainerForSingle {
  border-right: 1px dashed #ACACAC;
}

.graphContainer img {
  max-height: 100%;
  height: 88px;
  margin-left: -1px;
  border-right: 1px dashed #ACACAC;
}

.graphContainerForSingle img {
  max-height: 100%;
  height: 72px;
  margin-top: 16px;
}

.stagName {
  font-size: 12px;
  line-height: 150%;
  color: #737373;
  margin-top: 4px;
}

.estrogen,
.progesterone,
.estrogen-dark,
.progesterone-light {
  font-size: 12px;
  line-height: 150%;
}

.estrogen-dark {
  color: #728860;
}

.estrogen {
  color: #F5B94B;
}

.progesterone-light {
  color: #8FAF74;
}

.progesterone {
  color: #708B5E;
}



.w_1100 {
  min-width: 1100px;
}

.w_1180 {
  min-width: 1180px;
}


/* Edit tabs */

.editTab {
  color: #000000;
  background: none;
  border: none;
  font-size: 16px;
  line-height: 150%;
  margin: 0 16px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  padding-bottom: 4px;
}

.editTab:first-child {
  margin-left: 0;
}

.editTab:last-child {
  margin-right: 0;
}

/* Arrows */
.positive_arrow svg,
.negative_arrow svg {
  height: 16px;
  width: 14px;
}

/* on focus link */

.focus_text {
  color: #000000;
  font-size: 16px;
  line-height: 140%;
}

.focus_text:hover {
  color: #010101;
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (max-width: 576px) {
  .main_content_area {
    padding: 0px 0 60px;
  }

  .editTab {
    font-size: 12px;
    line-height: 160%;
    margin: 0 6px;
    width: 80px;
  }
}
@font-face {
  font-display: swap;
  font-family: EuclidSquare;
  font-style: normal;
  font-weight: 400;
  src: local("EuclidSquare Regular"), local("EuclidSquare-Regular"),
    url(/static/media/EuclidSquare-Regular.4254f8cf.woff2) format("woff2");
}

@font-face {
  font-display: swap;
  font-family: EuclidSquare;
  font-style: normal;
  font-weight: 700;
  src: local("EuclidSquare Medium"), local("EuclidSquare-Medium"),
    url(/static/media/EuclidSquare-Medium.67181e53.woff2) format("woff2");
}

@font-face {
  font-display: swap;
  font-family: EuclidSquare;
  font-style: normal;
  font-weight: 900;
  src: local("EuclidSquare Bold"), local("EuclidSquare-Bold"),
    url(/static/media/EuclidSquare-Bold.665a65f4.woff2) format("woff2");
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  -webkit-appearance: none;
  font-family: EuclidSquare, sans-serif;
  overflow-x: hidden;
  text-rendering: geometricPrecision;
}

.App {
  background-color: #ffffff;
  min-height: 100%;
  width: 100%;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
}

button {
  cursor: pointer;
}

input {
  width: 100%;
  font-family: "EuclidSquare";
  color: #010101;
  box-shadow: none;
  border: none;
  outline: none;
  padding: 0;
  font-size: 16px;
  line-height: 115%;
  font-style: normal;
  font-weight: normal;
}

textarea {
  border: none;
  background-color: transparent;
  resize: none;
  outline: none;
  font-family: "EuclidSquare";
  width: 100%;
}

ul,
li {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: 44px;
  line-height: 120%;
}

h2 {
  font-size: 32px;
  line-height: 120%;
}

h3 {
  font-size: 28px;
  line-height: 120%;
}

h4 {
  font-size: 24px;
  line-height: 140%;
}

h5 {
  font-size: 20px;
  line-height: 160%;
}

h6 {
  font-size: 18px;
  line-height: 160%;
}

p {
  font-size: 14px;
  line-height: 160%;
}

iframe {
  border: 0 !important;
  border-radius: 8px;
  box-shadow: none;
  outline: none;
}

img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  filter: none;
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -ms-filter: blur(0px);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-font-smoothing: subpixel-antialiased;
}

::selection {
  color: #ffffff;
  background-color: #010101;
}

::-webkit-input-placeholder {
  font-weight: normal;
}

::placeholder {
  font-weight: normal;
}

/* General Styles */
.flex {
  display: flex;
}

.flex_wrap {
  flex-wrap: wrap;
}

.block {
  display: block;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.justifyBetween {
  justify-content: space-between;
}

.justifyCenter {
  justify-content: center;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.alignStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.h-100vh {
  height: 100vh;
}

.w_96vw {
  width: 96vw;
  margin-right: 2rem;
}

.w-100vw {
  width: 100vw;
}

.grecaptcha-badge {
  visibility: hidden;
}

.dark_bg {
  background: #000;
  color: #fff;
}

.light_bg {
  background: #fff;
  color: #000;
}

.disabled_light_bg {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.pointer {
  cursor: pointer;
}

/* Preloader */

/* Error Toast */
.error_block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 0 16px;
}

.error_block_message {
  font-size: 16px;
  line-height: 1.4;
  color: #ffffff;
  text-align: center;
  margin: 0 16px;
}

.model_popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.4);
}

.model_popup .forgot_password_container {
  background: #ffffff;
  height: 350px;
  width: 500px;
  border-radius: 8px;
  padding: 24px;
}

/* Notification */
.notification_popup {
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 1000;
}

.notification_popup p {
  text-align: center;
  font-size: 16;
  line-height: 1.4;
  color: #ffffff;
  margin: 0px 24px;
}

@media screen and (min-width: 992px) {
  .desktop {
    display: flex !important;
  }

  .mobile {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }

  .model_popup .forgot_password_container {
    width: 96% !important;
  }

  .notification_popup {
    height: auto;
    padding: 20px 12px;
  }

  .notification_popup p {
    line-height: 1.66;
  }
}

.checkmark {
  position: absolute;
  top: -10px;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1.38px solid #000000;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox_container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.checkbox_container input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox_container:hover input ~ .checkmark {
  background-color: #ffffff;
}

/* When the checkbox is checked, add a blue background */

.checkbox_container input:checked ~ .checkmark {
  background-color: #000000;
}

.checkbox_container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox_container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.main_container__3KO27 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main_job_details__m0Sj5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 980px;
  text-align: center;
  margin: 64px auto;
}

.main_job_details__m0Sj5 p {
  margin: 16px 0 6px;
  text-align: justify !important;
}


.main_job_details__m0Sj5 ul {
  margin: 0 0 0 16px;
}

.main_job_details__m0Sj5 ul,
.main_job_details__m0Sj5 li {
  list-style-type: inherit !important;
  text-align: left;
}

.main_job_details__m0Sj5 li {
  margin: 8px 0 0;
}

.main_team_member_details__3qFUo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 980px;
  text-align: center;
  margin: 120px auto 0;
}

.main_gift_content_container__26Mcl {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 64px 40px 0;
}

.main_thankYou_content_container__JFoZr {
  height: 100%;
  width: 100%;
  max-width: 425px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 64px 40px 0;
}

.main_thankYou_content_container__JFoZr .main_title__rOuOz {
  font-size: 22px;
  line-height: 140%;
  margin: 0px 0px 40px -16px;
  color: #000000
}

.main_thankYou_content_container__JFoZr .main_thankYou_list__1muB5 li {
  list-style-type: disc !important;
  margin: 8px 0 !important;
  font-size: 16px;
  line-height: 140%;
}

.main_team_member_details__3qFUo h1,
.main_team_member_details__3qFUo h2,
.main_team_member_details__3qFUo h3,
.main_team_member_details__3qFUo h4,
.main_team_member_details__3qFUo h5,
.main_team_member_details__3qFUo h6 {
  margin: 25px 0;
  line-height: 1.256;
}

.main_team_member_details__3qFUo p {
  font-size: 16px;
  line-height: 150%;
  margin: 25px 0;
}

.main_team_member_details__3qFUo a {
  color: #010101;
}

.main_team_member_details__3qFUo img {
  max-height: 520px;
}

.main_show_for_desktop__2UeFt,
.main_show_for_mobile__37zP4 {
  width: 100%;
}

@media screen and (min-width: 992px) {
  .main_show_for_desktop__2UeFt {
    display: flex !important;
  }

  .main_show_for_mobile__37zP4 {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .main_show_for_desktop__2UeFt {
    display: none !important;
  }

  .main_show_for_mobile__37zP4 {
    display: flex !important;
  }

  .main_job_details__m0Sj5 {
    width: 90%;
  }

  .main_gift_content_container__26Mcl {
    flex-direction: column;
    padding: 56px 20px 0;
  }

  .main_thank_you_card__ycJel {
    width: 96% !important;
    padding: 16px !important;
    min-height: 576px !important;
    text-align: center !important;
  }

  .main_tick_circle__25xaB {
    height: 120px !important;
    width: 120px !important;
  }
}

/* Thank you Modal */
.main_thank_you_modal__LLLJH {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.4);
  z-index: 100;
}

.main_thank_you_card__ycJel {
  background: #ffffff;
  min-height: 520px;
  width: 576px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main_thank_you_card__ycJel>p {
  text-align: center;
  margin: 20px auto;
  width: 75%;
}

.main_tick_circle__25xaB {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #27AE60;
  border-radius: 50%;
  height: 160px;
  width: 160px;
}
.banners_main__3RCpH {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #00000070;
}

.banners_main__3RCpH .banners_after__Rh0dm {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.banners_main__3RCpH .banners_home_after__9u4Nj {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.banners_tiled__1Wy_P {
  position: absolute;
  bottom: -1px;
  right: -1px;
  border-left: 100vw solid transparent !important;
  border-bottom: 160px solid #ffffff;
  z-index: 1;
}

.banners_team_tiled__1YB1r {
  position: absolute;
  bottom: -1px;
  right: -1px;
  border-left: 100vw solid transparent !important;
  border-bottom: 160px solid #000000;
  z-index: 1;
}

.banners_image__3xIfx {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 100%;
  width: 100%;
}

.banners_homepage_banner__2r5Ac,
.banners_homepage_background_image__27WbK {
  min-height: 880px;
  min-width: 100vw;
}

.banners_community_banner__Q72Zf,
.banners_community_background_image__1Hv2h {
  min-height: 100vh;
  min-width: 100vw;
}

.banners_teampage_banner__124oi,
.banners_teampage_background_image__BV-9A {
  min-height: 1600px;
}

.banners_gympasspage_background_image__3xAon,
.banners_gympasspage_banner__16rlS {
  min-height: 984px;
}

.banners_privacypage_banner__1X3zA,
.banners_privacypage_background_image__1O-wN,
.banners_termspage_banner__3VYEl,
.banners_termspage_background_image__3mFtc {
  min-height: 672px;
}

.banners_aboutpage_banner__1Hbdv,
.banners_aboutpage_background_image__2KTtH {
  min-height: 1120px;
}

.banners_giftpage_banner__thVp4,
.banners_thankyoupage_banner__2Zqiv,
.banners_giftpage_background_image__uzcnN,
.banners_thankyoupage_background_image__3F2Co {
  min-height: 520px;
}

.banners_teampage_memberview__2K8l9,
.banners_teampage_memberview_background_image__1Zljw {
  min-height: 730px;
}

.banners_coachpage_banner__2JOB4,
.banners_coachpage_background_image__3wPC_ {
  min-height: 856px;
}

.banners_methodpage_banner__31e8G,
.banners_methodpage_background_image__n33D5 {
  min-height: 984px;
}

.banners_coaches_banner__2D3ii,
.banners_coaches_banner_background_image__2ajlf {
  min-height: 900px;
}

.banners_methoddetailpage_banner__1pnzo,
.banners_methoddetailpage_background_image__1_24H {
  min-height: 648px;
}

.banners_blogspage_banner__3Yx3E,
.banners_blogspage_background_image__3qO0K,
.banners_jobspage_banner__2KXQg,
.banners_jobspage_background_image__2KVCN {
  min-height: 749px;
}

.banners_marketplace_banner__3Z-sU,
.banners_marketplace_background_image__2VaMr {
  min-height: 320px;
}

.banners_jobspage_jobview__15Tqm,
.banners_jobspage_jobview_background_image__1lbCe {
  min-height: 656px;
}

.banners_faqpage_faqview__3SdMp,
.banners_faqpage_faqview_background_image__2yAzU {
  min-height: 356px;
}

.banners_gympasspage_background_image__3xAon,
.banners_privacypage_background_image__1O-wN,
.banners_termspage_background_image__3mFtc,
.banners_aboutpage_background_image__2KTtH,
.banners_giftpage_background_image__uzcnN,
.banners_thankyoupage_background_image__3F2Co {
  background-image: url(https://wild-blog-strapi.s3.amazonaws.com/home_ff0b9812d3.jpg?7765595.7);
}

/* .giftpage_background_image,
.thankyoupage_background_image {
    background-image: url(https://media.wild.ai/assets/images/gift-background.jpg);
} */

.banners_coachpage_background_image__3wPC_ {
  background-image: url(https://wild-blog-strapi.s3.amazonaws.com/image_72_e4e5bd6e8c.jpg?610431.8000000007);
}

.banners_teampage_background_image__BV-9A,
.banners_jobspage_background_image__2KVCN,
.banners_teampage_memberview_background_image__1Zljw,
.banners_jobspage_jobview_background_image__1lbCe {
  background-image: url(https://wild-blog-strapi.s3.amazonaws.com/teams_dba5cb5187.jpg?3958210.2);
}

.banners_methodpage_background_image__n33D5,
.banners_methoddetailpage_background_image__1_24H {
  background-image: url(https://wild-blog-strapi.s3.amazonaws.com/wild_wiki_68e40464b2.jpg?213734.90000000037);
}

.banners_coaches_banner_background_image__2ajlf {
  background-image: url(https://res.cloudinary.com/farooq-cloud-db/image/upload/v1686566406/pexels-mica-asato-1198174_dupmdz.jpg);
}

.banners_blogspage_background_image__3qO0K {
  background-image: url(https://wild-blog-strapi.s3.amazonaws.com/blogs_f792d728ce.jpg?11850074.2);
}

.banners_marketplace_background_image__2VaMr {
  background-image: url(https://wild-blog-strapi.s3.amazonaws.com/wild_wiki_68e40464b2.jpg);
}

@media screen and (max-width: 992px) {
  .banners_homepage_banner__2r5Ac,
  .banners_homepage_background_image__27WbK {
    min-height: 920px;
  }

  .banners_aboutpage_banner__1Hbdv,
  .banners_aboutpage_background_image__2KTtH {
    min-height: 1366px;
  }

  .banners_giftpage_banner__thVp4,
  .banners_giftpage_background_image__uzcnN {
    min-height: 800px;
  }

  .banners_thankyoupage_banner__2Zqiv,
  .banners_thankyoupage_background_image__3F2Co {
    min-height: 600px;
  }

  .banners_tiled__1Wy_P {
    display: none;
  }

  .banners_blogspage_banner__3Yx3E,
  .banners_blogspage_background_image__3qO0K {
    min-height: 680px;
  }

  .banners_marketplace_banner__3Z-sU,
  .banners_marketplace_background_image__2VaMr {
    min-height: 260px;
  }

  .banners_jobspage_banner__2KXQg,
  .banners_jobspage_background_image__2KVCN,
  .banners_coachpage_banner__2JOB4,
  .banners_coachpage_background_image__3wPC_,
  .banners_privacypage_banner__1X3zA,
  .banners_privacypage_background_image__1O-wN,
  .banners_termspage_banner__3VYEl,
  .banners_termspage_background_image__3mFtc {
    min-height: 520px;
  }

  .banners_teampage_banner__124oi,
  .banners_teampage_background_image__BV-9A {
    min-height: 3200px;
  }
}

/* Black Button */
.buttons_black__6KJOy,
.buttons_black__6KJOy:hover {
  border: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: transparent;
  text-align: center;
  color: #ffffff !important;
  background-color: #000000;
  border-radius: 60px;
  height: 56px;
  letter-spacing: 2px;
  width: 100%;
  box-shadow: none !important;
  text-transform: uppercase;
  cursor: pointer !important;
  text-decoration: none;
  -webkit-filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important;
          filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important;
}

/* White Button */
.buttons_white__1cYIa,
.buttons_white__1cYIa:hover {
  border: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: transparent;
  text-align: center;
  color: #010101 !important;
  background-color: #ffffff;
  border-radius: 24px;
  letter-spacing: 2px;
  height: 46px;
  width: 100%;
  box-shadow: none;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  -webkit-filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important;
          filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important;
}

.buttons_white__1cYIa p,
.buttons_black__6KJOy p {
  line-height: 120% !important;
  font-size: 14px !important;
  font-family: "EuclidSquare";
  cursor: pointer;
}

.buttons_without_showdow__3eHYy {
  -webkit-filter: none !important;
          filter: none !important;
}

.buttons_without_showdow__3eHYy p {
  text-transform: uppercase !important;
}

.buttons_black__6KJOy p::selection {
  background: #ffffff !important;
  color: #010101 !important;
}

.home_main__R5izQ {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 1080px;
  position: relative;
  overflow: hidden;
  background-color: #000;
}

.home_main__R5izQ .home_after__2bJRV {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.home_tiled__1FnZD {
  position: absolute;
  bottom: 0;
  right: 0;
  border-left: 100vw solid transparent;
  border-bottom: 120px solid #ffffff;
  z-index: 1;
}

.home_image__3h7Yw {
  background-image: url(/assets/images/main-image.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100%;
  min-height: 1080px;
  width: 100%;
}

.home_content__3IXIR {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto 50px;
  z-index: 10;
}

.home_gympass_banner_content__3JzVC {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  margin: -160px auto 0;
  z-index: 10;
}

.home_gympass_features_content__mifi1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  max-width: 784px;
  text-align: center;
  margin: 0 auto;
  z-index: 10;
}

/* Tab screen styles */

.home_tab_screen__2mizs {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.home_tab_content__3-pmN {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.home_image_slider_desktop__3iyh3,
.home_image_slider_gympass__fMo7y {
  width: 100%;
  max-width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_image_slider_desktop__3iyh3 {
  margin-top: -220px;
}

.home_align_center__2pz7B {
  align-items: center;
}

.home_tab_layout__1DxC7 {
  width: 100%;
  max-width: 550px;
  margin-left: 98px;
  display: flex;
  flex-direction: column;
}

.home_tab_layout__1DxC7 h3 {
  margin-bottom: 32px;
  font-weight: 700;
}

.home_backward__2EfLJ {
  margin-right: 48px;
}

.home_forward__2_2Cj {
  margin-left: 48px;
}

.home_backward__2EfLJ,
.home_forward__2_2Cj {
  background-color: #010101;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.home_device_frame__38lpu {
  -webkit-filter: drop-shadow(0px 30.325px 52.0505px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 30.325px 52.0505px rgba(0, 0, 0, 0.25));
  width: 296px !important;
  height: 601px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_tabs__2a5BD {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.home_tabs__2a5BD .home_tab__1ahaS {
  margin-right: 16px;
  cursor: pointer;
}

.home_tabs__2a5BD .home_tab__1ahaS p {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #010101;
  font-size: 14px;
  line-height: 120%;
  font-weight: 700;
  padding: 0 16px;
}

.home_tabs_content__1U8B1 {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}

.home_sliding_content_active__377vm,
.home_sliding_content_inactive__1KSEO {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 21px;
}

.home_sliding_content_active__377vm {
  width: 396px;
}

.home_sliding_content_active__377vm .home_sliding_active_arrow__1zui8 {
  margin-right: 24px;
}

.home_active_content__qao1T {
  color: #010101;
  width: 396px;
  margin-left: 20px !important;
}

.home_inactive_content__q40Uz,
.home_sliding_content_inactive__1KSEO {
  color: rgba(1, 1, 1, 0.2);
  width: 420px;
}

.home_social_apps_logos__2IsG4 {
  display: flex;
  width: 100%;
  padding-top: 36px;
  max-width: 290px;
  justify-content: space-between;
}

.home_social_apps_logos__2IsG4 a {
  height: 140px;
  height: 49px;
}

/* Life Stage Support styles */

.home_life_stage_support__2jT-J {
  position: relative;
  height: 100%;
  width: 100%;
}

.home_support_overlay__3GFvz {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 1540px;
}

.home_support_overlay__3GFvz .home_support_bg__19tme {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 1540px;
  max-height: 1550px;
  object-position: center 20%;
}

.home_support_overlay_content__2kuyo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.8);
}

.home_support_content__1tgVT {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  z-index: 10;
}

.home_vertical_row__3_0RP {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 392px;
}

.home_vertical_line__1L5x5 {
  height: 8px;
  background: #ffffff;
  width: 2px;
  margin-bottom: 8px;
}

.home_vertical_half_line_top__3V0wR {
  height: 4px;
  background: #ffffff;
  width: 2px;
  margin-bottom: 0px;
}

.home_vertical_half_line_bottom__1ypVV {
  height: 4px;
  background: #ffffff;
  width: 2px;
  margin-bottom: 8px;
}

.home_support_details__18yPX {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_support_icon_box__dIgbo {
  position: relative;
}

.home_support_icon__19IXl {
  background: #ffffff;
  height: 73px;
  width: 72px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.home_support_icon_details_box__-bNR_ {
  position: absolute;
  bottom: 80px;
  width: 100%;
  height: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 540px;
  max-width: 540px;
  min-height: 320px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 24px 40px;
  z-index: 10;
  box-shadow: 0px 25px 47px rgba(0, 0, 0, 0.3);
}

.home_support_icon_details__3iCFV {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.home_support_icon_details__3iCFV h3 {
  color: #010101;
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
  width: 100%;
}

.home_support_icon_details__3iCFV p {
  margin: 8px 0 0;
  color: #010101;
}

.home_support_icon_details__3iCFV ul li {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
}

.home_support_icon_details__3iCFV ul li p {
  margin: 0 0 0 8px !important;
  font-size: 16px;
  line-height: 140%;
}

.home_support_icon_empty__210o8 {
  background: #ffffff;
  height: 29px;
  width: 29px;
  border-radius: 50%;
}

.home_support_icon__19IXl img {
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: all 300ms ease-in-out;
}

.home_support_icon__19IXl img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: all 300ms ease-in-out;
}

.home_vertical_row_left__3F3GB {
  justify-content: flex-end;
}

.home_vertical_row_left__3F3GB,
.home_vertical_row_right__2-XW3 {
  display: flex;
  width: 160px;
  align-items: center;
  justify-content: center;
}

.home_vertical_row_left_content__3U0AE,
.home_vertical_row_right_content__2PN-y {
  display: flex;
  flex-direction: column;
}

.home_vertical_row_right__2-XW3 {
  justify-content: flex-start;
}

.home_vertical_row_center__P0Hd6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 24px;
}

.home_timeline__3P2hx,
.home_timeline_fade__CifgZ {
  font-size: 20px;
  line-height: 140%;
  color: #ffffff;
  font-weight: 600;
}

.home_description__3kcMY,
.home_description_fade__3ZdsR {
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
}

@media screen and (max-width: 992px) {
  .home_vertical_row_left__3F3GB,
  .home_vertical_row_right__2-XW3 {
    width: 120px;
  }

  .home_vertical_row_center__P0Hd6 {
    margin: 0 16px;
  }

  .home_timeline__3P2hx,
  .home_timeline_fade__CifgZ {
    font-size: 17px;
  }
}

.home_timeline_fade__CifgZ,
.home_description_fade__3ZdsR {
  opacity: 0.6;
}

.home_close_dialog__1nelM {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  width: 100%;
}

.home_dot___-poR {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #000;
}

/* Wild sync - homepage */

.home_wild_sync__3XaE6 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 56px;
  height: 420px;
  width: 100%;
  max-width: 1100px;
}

.home_wild_sync__3XaE6 .home_wild_sync_title__1Xwvo {
  color: #000000;
}

.home_wild_sync__3XaE6 .home_wild_sync_description__2BTu7 {
  color: #000000;
  font-size: 18px;
  line-height: 140%;
  margin: 8px 0 40px;
}

.home_wild_sync_logos__3IU2N {
  margin: 32px 0 0;
}

.home_wild_sync_logos__3IU2N,
.home_wild_partner_logos__9aeT2 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}

.home_wild_partner_logos__9aeT2 {
  max-width: 920px;
  margin-top: 24px;
}

.home_wild_sync_logos__3IU2N img {
  object-fit: contain !important;
  padding: 12px 16px !important;
}

.home_wild_partner_logos__9aeT2 a {
  margin: 28px 18px !important;
}

.home_wild_sync_logos__3IU2N img:first-child {
  margin-left: 0px !important;
}

.home_wild_sync_logos__3IU2N img:last-child {
  margin-right: 0px !important;
}

/* 

.wild_sync_logos>div:first-child {
  margin-left: 0px !important;
}

.wild_sync_logos>div:last-child {
  margin-right: 0px !important;
} */

/* Testimonials */

.home_testimonial_container__1d_k2 {
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 768px;
}

.home_wild_partner_banner__2c1Q7 {
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 576px;
}

/* Newsletter styles */

.home_newletter_container__2bsY9 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 520px;
}

.home_newletter_box__2OGb_ {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 618px;
}

.home_newletter_box__2OGb_ > p {
  color: #000;
  width: 222px;
  font-size: 27.54px;
  line-height: 120%;
}

.home_newletter_box__2OGb_ input {
  background-color: #fff;
  color: #000 !important;
  border-bottom: 1px solid #ffffff !important;
}

.home_newletter_box__2OGb_ input::-webkit-input-placeholder {
  color: #737373 !important;
}

.home_newletter_box__2OGb_ input::placeholder {
  color: #737373 !important;
}

/* News styles */

.home_latest_news__2TTOD {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  background: #000;
  min-height: 1020px;
  padding: 56px 0 0;
}

.home_latest_news__2TTOD .home_news_row__gECo0 {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 80px;
}

.home_news_box__3B0ON {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 437px;
  align-items: flex-start;
  justify-content: flex-start;
  text-decoration: none;
}

.home_news_box__3B0ON .home_image_shadow_container__3540n {
  -webkit-filter: drop-shadow(0px 25px 47px rgba(0, 0, 0, 0.3));
          filter: drop-shadow(0px 25px 47px rgba(0, 0, 0, 0.3));
  background: #ffffff;
  padding: 10px;
}

.home_news_content__3Gn98 {
  width: 100%;
  padding: 10px;
  margin-top: 30px;
}

.home_news_content__3Gn98 .home_date__BWpru {
  font-size: 14px;
  line-height: 150%;
  color: rgba(1, 1, 1, 0.4);
}

.home_news_content__3Gn98 h4 {
  color: #010101;
  margin: 10px 0 0;
  text-transform: capitalize;
}

.home_news_content__3Gn98 > p {
  color: #010101;
  margin: 20px 0 0;
  font-size: 16px;
  line-height: 140%;
}

.home_read_more_box__2TP_Q,
.home_latest_news_button__3DBm6 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: -webkit-max-content;
  max-width: max-content;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: none !important;
}

.home_latest_news_button__3DBm6 {
  margin: 80px 0 !important;
}

.home_read_more_box__2TP_Q {
  justify-content: flex-start;
}

.home_read_more_box__2TP_Q p {
  color: rgba(1, 1, 1, 0.8);
  margin-right: 16px;
}

.home_latest_news_button__3DBm6 {
  justify-content: center;
  margin: 56px 0 0;
}

/* Coach subscribe section styles */

.home_coach_section__1W1yk {
  height: 100%;
  width: 100%;
  min-height: 1080px;
  padding: 56px 0 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home_coach_section_title__2Ewja {
  color: #010101;
  margin-top: 80px;
}

.home_coach_section_description__2s65Q {
  color: #010101;
  margin: 40px 0 0;
  font-size: 27.54px;
  line-height: 120%;
}

.home_coach_image_box__2R3K3 {
  position: relative;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 78px 0 0;
  z-index: 100;
  background: #ffffff;
  padding: 20px;
  box-shadow: 2px 0px 27px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 0px 27px 10px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 2px 0px 27px 10px rgba(0, 0, 0, 0.15);
  max-width: 673px;
  border-radius: 20px;
}

.home_athletes_cover_images_container__1ibKc {
  position: absolute;
  right: 0;
  bottom: 60px;
  -webkit-transform: translateX(120px);
          transform: translateX(120px);
}

.home_coach_section__1W1yk .home_coach_form__2CN4E {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.home_coach_form__2CN4E .home_coach_app_overview__3HjQQ {
  width: 100%;
  max-width: 452px;
}

.home_coach_app_overview__3HjQQ p {
  color: #010101;
}

.home_coach_app_overview__3HjQQ .home_bullets__1ij_z {
  margin-top: 40px;
  max-width: 394px;
}

.home_coach_app_overview__3HjQQ .home_bullets__1ij_z p {
  font-weight: bold;
  color: #010101;
}

.home_coach_form__2CN4E .home_coach_app_subscribe__3ZXKq {
  width: 100%;
  max-width: 520px;
}

.home_bullets_box__30gg6 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  max-width: 80%;
}

.home_bullets_container_left__39hj2,
.home_bullets_container_right__29SW0 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.home_bullets_container_left__39hj2 {
  max-width: 452px;
  padding: 0 24px;
}

.home_bullets_container_right__29SW0 {
  max-width: 332px;
}

.home_bullets_container_left__39hj2 ul li {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 0 8px;
  margin: 0;
}

.home_bullets_container_left__39hj2 ul li p {
  margin: 0 0 0 12px !important;
  font-size: 17px;
  line-height: 150%;
  font-weight: 700;
}

.home_bullets_container_left__39hj2 .home_dot___-poR {
  width: 100%;
  height: 100%;
  min-width: 6px;
  min-height: 6px;
  max-width: 6px;
  max-height: 6px;
  border-radius: 50%;
  background: #000;
  margin-top: 8px;
}

/* Team section styles */

.home_team_banner__8k2es {
  height: 100%;
  width: 100%;
  min-height: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000;
  position: relative;
}

.home_team_title__119nt {
  color: #fff;
  margin-top: 146px;
}

.home_team_description__3Um_a {
  color: #fff;
  margin: 40px 0 0;
  font-size: 27.54px;
  line-height: 120%;
  text-align: center;
}

@media screen and (min-width: 992px) {
  .home_show_for_desktop__3-jqw {
    display: flex !important;
  }

  .home_show_for_mobile__32qUP {
    display: none !important;
  }

  .home_tab_content__3-pmN {
    margin-bottom: 240px;
  }
}

/* Premium banner */

.home_premium_text__H0w3D,
.home_normal_text__37M6L,
.home_welcome_text__1T45k {
  text-align: center;
  color: #ffffff;
}

.home_premium_text__H0w3D {
  font-size: 10.3212px;
  line-height: 140%;
}

.home_welcome_text__1T45k {
  font-size: 45px;
  text-transform: capitalize;
  line-height: 120%;
  margin: 30px 0 0;
}

.home_normal_text__37M6L {
  font-size: 16px;
  line-height: 140%;
  padding: 0 32px;
  text-align: center;
  margin: 20px 0 0;
}

.home_features_row__1hcUC {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 784px;
  margin: 50px 0;
}

.home_feature_box__1XPf2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: auto;
  width: 100%;
  max-width: 332px;
  padding: 0 24px;
}

.home_feature_image_container__1Xh4u {
  height: 88px;
  width: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000;
  border-radius: 50%;
  box-shadow: 0px 8.25px 23.375px rgba(0, 0, 0, 0.25);
}

.home_feature_box__1XPf2 p {
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  margin: 20px 0;
}

/* about page */

.home_wild_sync_light_logos__2C69Z {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.home_wild_sync_light_logos__2C69Z img {
  object-fit: contain !important;
  padding: 12px 6px !important;
}

.home_wild_sync_light_logos__2C69Z img:first-child {
  margin-left: 0px !important;
}

.home_wild_sync_light_logos__2C69Z img:last-child {
  margin-right: 0px !important;
}

.home_coach_section_margin__3DOc4 {
  margin: 180px 0 0;
}

@media screen and (max-width: 992px) {
  .home_show_for_desktop__3-jqw {
    display: none !important;
  }

  .home_show_for_mobile__32qUP {
    display: flex !important;
  }

  .home_team_banner__8k2es {
    padding: 50px 0 80px;
  }

  .home_landing_banner_content__1NGn7 {
    margin: -80px auto 0;
  }

  .home_gympass_banner_content__3JzVC {
    margin: -220px auto 0;
  }

  .home_tab_layout__1DxC7 {
    margin-left: 0px;
  }

  .home_features_column__2nbAY {
    margin: 50px auto;
  }

  .home_features_row__1hcUC {
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;
  }

  .home_feature_box__1XPf2 {
    margin: 26px auto;
  }

  .home_welcome_text__1T45k {
    font-size: 27px;
    line-height: 120%;
  }

  .home_newletter_box__2OGb_ {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 50px auto;
    max-width: 90% !important;
  }

  .home_newletter_container_left__34gzL {
    padding: 0px 24px 48px;
  }

  .home_newletter_container_right__3-xfr,
  .home_newletter_container_right__3-xfr h4 {
    text-align: left;
    width: 100%;
    line-height: 140%;
    max-width: 332px;
  }

  .home_news_row__gECo0 {
    width: 96% !important;
    max-width: 437px;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
  }

  .home_bullets_box__30gg6 {
    flex-direction: column;
    align-items: center;
    max-width: 90%;
  }

  .home_bullets_container_right__29SW0 {
    margin-top: 40px;
  }

  .home_latest_news__2TTOD {
    min-height: 1560px !important;
  }

  .home_news_box__3B0ON {
    margin: 0 auto 50px;
  }

  .home_coach_section_title__2Ewja,
  .home_coach_section_description__2s65Q,
  .home_team_title__119nt,
  .home_team_description__3Um_a {
    max-width: 437px;
    width: 96%;
    text-align: center;
  }

  .home_coach_section_description__2s65Q {
    margin: 40px 0;
  }

  .home_coach_section_title__2Ewja {
    line-height: 120%;
  }

  .home_team_members_row__1hCLM {
    flex-direction: column;
    justify-content: center;
  }

  .home_wild_sync__3XaE6 {
    height: 520px;
  }

  .home_wild_sync_logos__3IU2N {
    margin: 32px !important;
  }

  .home_wild_sync_description__2BTu7 {
    width: 90%;
    text-align: center;
  }

  .home_wild_sync_logos__3IU2N img {
    object-fit: contain !important;
  }

  .home_coach_section_margin__3DOc4 {
    margin: 56px 0 0;
  }

  .home_testimonial_container__1d_k2 {
    min-height: 992px;
  }

  .home_wild_partner_banner__2c1Q7 {
    min-height: 900px;
  }

  .home_support_overlay__3GFvz {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 1240px;
  }

  .home_support_overlay__3GFvz .home_support_bg__19tme {
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 1340px;
    max-height: 1340px;
    object-position: center 20%;
  }
}

@media screen and (max-width: 768px) {
  .home_support_icon_details_box__-bNR_ {
    min-width: 340px;
    max-width: 360px;
    min-height: 380px;
    border-radius: 20px;
    padding: 16px 16px 16px 20px;
  }

  .home_support_icon_details__3iCFV > h3 {
    font-size: 18px;
  }

  .home_support_icon_details__3iCFV > p {
    font-size: 16px;
  }

  .home_support_icon_details__3iCFV ul li {
    margin: 6px 6px 6px 0;
    position: relative;
  }

  .home_support_icon_details__3iCFV ul li div {
    position: absolute;
    top: 8px;
  }

  .home_support_icon_details__3iCFV ul li p {
    margin-left: 16px !important;
    font-size: 14px;
  }
}

/* Highlight content having black background */

.home_team_title__119nt::selection,
.home_team_description__3Um_a::selection,
.home_newletter_box__2OGb_ p::selection,
.home_newletter_box__2OGb_ input::selection {
  background: #ffffff !important;
  color: #010101 !important;
}

/* Tabs */

@media screen and (min-width: 992px) {
  .shapes_mobile_stages_white_trapezoid_top_bottom__2YbXt {
    border-right: 26.5vw solid transparent;
    border-top: 56px solid #000;
    position: absolute;
    top: -44px;
    left: 0;
    z-index: 2;
  }
}

.shapes_news_border_top__30cdT {
  border-right: 100vw solid transparent;
  border-top: 80px solid #f7f7f7;
  position: absolute;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  top: 0;
  right: 0;
  z-index: 1;
}

.shapes_lightgray_border_top__2uDpq {
  border-right: 100vw solid transparent;
  border-top: 80px solid #fff;
  position: absolute;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  top: 0;
  right: 0;
  z-index: 1;
}

.shapes_lightgray_testimonial_border_top__5lRGB {
  border-right: 100vw solid transparent;
  border-top: 80px solid #ffffff;
  position: absolute;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  top: -1px;
  right: 0;
  z-index: 1;
}

.shapes_lightgray_coachsignup_border_top__3xrAj {
  border-left: 100vw solid transparent;
  border-top: 80px solid #000;
  position: absolute;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  top: -1px;
  right: 0;
  z-index: 1;
}

.shapes_lightgray_border_bottom__2G8BB {
  border-right: 100vw solid transparent;
  border-bottom: 80px solid #fff;
  position: absolute;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  z-index: 1;
  bottom: -1px;
  right: 0;
}

.shapes_support_border_top__82b6q {
  border-right: 100vw solid transparent;
  border-top: 80px solid #ffffff;
  position: absolute;
  top: -1px;
  right: 0;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  z-index: 1;
}

.shapes_support_border_bottom__3Duud {
  border-right: 100vw solid transparent;
  border-bottom: 80px solid #ffffff;
  position: absolute;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  z-index: 1;
  bottom: 0;
  right: 0;
}

.shapes_black_border_top_team__3bl3P {
  border-left: 100vw solid transparent;
  border-bottom: 80px solid #000;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.shapes_black_border_bottom__2sK3L {
  border-right: 100vw solid transparent;
  border-bottom: 80px solid #000;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
}

.shapes_black_border_top__3gsmd {
  border-left: 100vw solid transparent;
  border-top: 80px solid #ffffff;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
}

.shapes_black_white_border_top__wvhSV {
  border-left: 100vw solid transparent;
  border-bottom: 80px solid #ffffff;
  position: absolute;
  z-index: 1;
  bottom: 0px;
  left: 0;
}

.shapes_white_tiled__3VU8A {
  width: 30px;
  height: 30px;
  -webkit-transform: matrix(0.74, 0.75, -0.75, 0.74, 0, 0);
          transform: matrix(0.74, 0.75, -0.75, 0.74, 0, 0);
  background: #ffffff;
  position: absolute;
  z-index: 1;
  bottom: -10px;
}

.shapes_tiled_top_on_image__3_a-F {
  position: absolute;
  top: 0px;
  right: -8px;
  border-right: 213px solid transparent !important;
  border-top: 8px solid #ffffff;
  -webkit-transform: translate(0px, 0px);
          transform: translate(0px, 0px);
  z-index: 1;
}

.shapes_tiled_bottom_on_image__1i6xL {
  position: absolute;
  bottom: 0px;
  left: -8px;
  border-left: 213px solid transparent !important;
  border-bottom: 8px solid #ffffff;
  -webkit-transform: translate(0px, -4px);
          transform: translate(0px, -4px);
  z-index: 1;
}

.shapes_tiled_top__1XcP6 {
  position: absolute;
  top: -20px;
  right: 6px;
  background: transparent;
  border-left: 213px solid transparent !important;
  border-bottom: 20px solid #ffffff;
  z-index: 1;
}

.shapes_tiled_bottom__3vw7Y {
  position: absolute;
  bottom: -20px;
  right: 6px;
  background: transparent;
  border-right: 213px solid transparent !important;
  border-bottom: 20px solid #ffffff;
  z-index: 1;
}

.shapes_transparent_top__3Y9fE {
  position: absolute;
  top: -24px;
  right: 0px;
  border-left: 390px solid transparent;
  border-bottom: 24px solid #ffffff;
  z-index: 1;
}

.shapes_transparent_bottom__1K8GM {
  position: absolute;
  bottom: -24px;
  right: 0px;
  border-right: 390px solid transparent;
  border-top: 24px solid #ffffff;
  z-index: 1;
}

@media screen and (max-width: 922px) {
  .shapes_transparent_top__3Y9fE {
    top: -23px;
    border-left: 340px solid transparent;
  }

  .shapes_transparent_bottom__1K8GM {
    border-right: 340px solid transparent;
    bottom: -23px;
  }
}

/* Paralellogram cards tiled shapes */

.shapes_paralellogram_card_border_top__2u1eK {
  border-right: 213px solid transparent;
  border-bottom: 16px solid #ffffff;
  position: absolute;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  top: -16px;
  right: 0;
  z-index: 1;
}

.shapes_paralellogram_card_border_bottom__3OklC {
  border-left: 213px solid transparent;
  border-top: 16px solid #ffffff;
  position: absolute;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  bottom: -16px;
  right: 0;
  z-index: 1;
}

.shapes_paralellogram_card_border_top_on_image__1Gcql {
  border-left: 213px solid transparent;
  border-top: 16px solid #ffffff;
  position: absolute;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  top: 0;
  right: 0;
  z-index: 1;
}

.shapes_paralellogram_card_border_top_from_label_container__3Uu4u {
  border-right: 197px solid transparent;
  border-bottom: 16px solid #ffffff;
  position: absolute;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  top: -16px;
  right: 7.5px;
  z-index: 1;
}

.common-styles_content__4y7TO {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  z-index: 10;
}

.common-styles_banner__15oob {
  justify-content: center;
  position: relative;
  text-align: start;
  width: 100%;
}

.common-styles_content__4y7TO,
.common-styles_coach_content__3DPjc {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-height: 656px;
  width: 100%;
}

.common-styles_content__4y7TO {
  max-width: 850px;
  margin: auto;
}

.common-styles_coach_content__3DPjc {
  max-width: 616px !important;
  width: 100%;
  margin: 0 auto;
  min-height: 1060px;
}

.common-styles_team_member_view_page__2ksUq {
  min-height: 820px !important;
  max-width: 616px !important;
  text-align: center;
  margin: 96px auto 0 !important;
}

.common-styles_common_container__2dESg {
  width: 100%;
  height: 100%;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.common-styles_common_container__2dESg .common-styles_title__2IhKm {
  color: #ffffff;
  text-align: center;
  padding: 0px 16px;
}

@media screen and (max-width: 992px) {
  .common-styles_coach_content__3DPjc {
    min-height: 520px;
  }

  .common-styles_common_container__2dESg .common-styles_title__2IhKm {
    font-size: 38px;
    line-height: 1.256;
  }
}

.common-styles_common_container__2dESg .common-styles_search_field__2B9h0 {
  width: 100%;
  max-width: 746px;
  height: 100%;
  max-height: 45px;
  margin-top: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-styles_common_container__2dESg .common-styles_search_field__2B9h0 input {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  box-shadow: none;
  border: none;
  padding: 0 24px;
  font-size: 18px;
  line-height: 150%;
  color: #010101;
  font-family: inherit !important;
}

.common-styles_common_banner_content___IHhx {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  max-width: 1100px;
  margin: 64px 0 120px;
  position: relative;
}

.common-styles_common_banner_left_content__1-EIW,
.common-styles_common_banner_right_content__3YW-2 {
  width: 100%;
  max-width: 455px;
}

.common-styles_common_banner_content___IHhx li:first-child {
  font-size: 14px;
  line-height: 150%;
  margin: 8px 0 0;
  color: #010101;
}

.common-styles_common_banner_content___IHhx li {
  font-size: 14px;
  line-height: 150%;
  color: #010101;
}

.common-styles_common_banner_content___IHhx p {
  font-size: 14px;
  line-height: 150%;
  margin: 10px 0 0;
  color: #010101;
}

.common-styles_common_banner_content___IHhx a {
  color: #010101;
  margin-right: 4px;
  margin-left: 4px;
  text-transform: none;
}

.common-styles_common_banner_content___IHhx a:hover {
  color: #010101;
  text-decoration: underline;
}

.common-styles_member_name__2jQPJ {
  font-size: 72px;
  line-height: 110%;
  color: #ffffff;
  text-align: center;
  padding: 32px 16px 0;
}

.common-styles_designation__4JWcC {
  font-weight: 500;
  font-size: 32px;
  line-height: 120%;
  color: #ffffff;
  margin: 20px 0;
}

/* list container */

.common-styles_list_container__2YwFw {
  display: flex;
  flex-direction: column;
  margin: 64px 0 120px;
  width: 100%;
  max-width: 980px;
}

.common-styles_list_label__1q2Lz {
  margin: 40px 0;
  color: #000000;
  text-align: left;
  text-transform: capitalize;
}

.common-styles_list_dropdown_box__2IfjK {
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  width: 100%;
  border-bottom: 1px solid rgba(1, 1, 1, 0.4);
}

.common-styles_list_dropdown_box__2IfjK a {
  text-decoration: none;
}

.common-styles_list_dropdown_box__2IfjK .common-styles_title__2IhKm {
  font-size: 18px;
  line-height: 115%;
  color: #000000;
  cursor: pointer;
}

.common-styles_list_dropdown_box__2IfjK .common-styles_description__3XL5N {
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  margin: 25px 0;
}

@media screen and (max-width: 576px) {
  .common-styles_list_label__1q2Lz {
    font-size: 26px !important;
    line-height: 140% !important;
  }

  .common-styles_list_dropdown_box__2IfjK .common-styles_title__2IhKm {
    font-size: 16px !important;
    line-height: 140% !important;
    width: 80% !important;
  }

  .common-styles_list_dropdown_box__2IfjK .common-styles_description__3XL5N {
    font-size: 13px;
    line-height: 145%;
  }
}

/* Pricing banner */

.common-styles_pricing_banner__3nLbV {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 752px;
  margin: 0 auto;
}

.common-styles_pricing_row__1Pyn9 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-styles_pricing_banner__3nLbV .common-styles_pricing_card__2wlr4 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 56px 0 0;
  height: 556px;
  width: 100%;
  max-width: 390px;
  min-width: 390px;
  margin: 0 21px;
  background: #ffffff;
  border-radius: 12px;
}

.common-styles_pricing_banner__3nLbV .common-styles_pricing_card__2wlr4:first-child {
  margin-left: 0px;
}

.common-styles_pricing_banner__3nLbV .common-styles_pricing_card__2wlr4:last-child {
  margin-right: 0px;
}

.common-styles_bullets_for_life_support__3b7j_,
.common-styles_gift_bullets_for_life_support__JanFO {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-left: 1%;
}

.common-styles_pricing_card__2wlr4 ul {
  margin: 24px 0 32px;
  width: 280px;
}

.common-styles_life_stage_supports__2C8jG {
  margin: 80px auto 0;
  width: 100%;
  max-width: 678px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.common-styles_life_stage_supports__2C8jG h2,
.common-styles_life_stage_supports__2C8jG h3 {
  text-align: center;
}

.common-styles_bullets_for_life_support__3b7j_ ul,
.common-styles_gift_bullets_for_life_support__JanFO ul {
  width: 100%;
}

.common-styles_pricing_card__2wlr4 ul li,
.common-styles_bullets_for_life_support__3b7j_ ul li,
.common-styles_gift_bullets_for_life_support__JanFO ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0 0;
  font-size: 16px;
  line-height: 140%;
  color: #565656;
}

.common-styles_bullets_for_life_support__3b7j_ ul li {
  margin: 24px 0 0 !important;
}

.common-styles_pricing_card__2wlr4 ul li span,
.common-styles_bullets_for_life_support__3b7j_ ul li span,
.common-styles_gift_bullets_for_life_support__JanFO ul li span {
  margin-right: 10px;
  height: 24px;
  min-width: 24px;
}

.common-styles_bullets_for_life_support__3b7j_ ul li span {
  margin-right: 24px;
}

.common-styles_payment__1J0tr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 32px;
}

.common-styles_payment__1J0tr h4 {
  font-size: 27px;
  line-height: 120%;
  color: #000000;
}

.common-styles_payment__1J0tr p {
  font-size: 16px;
  line-height: 140%;
  color: #565656;
  margin-top: 8px;
}

.common-styles_saving__oBX8B {
  position: absolute;
  right: 35px;
  bottom: 130px;
  height: 64px;
  width: 64px;
  background: #e8494e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.common-styles_saving__oBX8B p {
  margin-top: 14px;
  font-size: 16px;
  line-height: 120%;
  color: #ffffff;
}

.common-styles_wild_sync__1LdBP {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  margin: 120px auto 60px;
}

.common-styles_wild_sync__1LdBP .common-styles_image_box__1Uydx {
  position: relative;
  width: 100%;
  max-width: 745px;
  height: 900px;
}

.common-styles_wild_sync__1LdBP .common-styles_image_overlay__15vd_ {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.common-styles_wild_sync__1LdBP .common-styles_sync_container__yF1qZ {
  background: #000000;
  width: 100%;
  height: 100%;
  min-height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.common-styles_wild_sync__1LdBP .common-styles_sync_content__8knYx {
  width: 100%;
  height: 100%;
  max-width: 530px;
  margin: auto;
}

.common-styles_wild_sync__1LdBP .common-styles_sync_content__8knYx h2,
.common-styles_wild_sync__1LdBP .common-styles_sync_content__8knYx h3 {
  margin: 24px 0;
}

.common-styles_wild_sync__1LdBP .common-styles_sync_content__8knYx ul {
  margin: 40px 0;
}

.common-styles_wild_sync__1LdBP .common-styles_sync_content__8knYx li {
  font-size: 16px;
  line-height: 180%;
}

.common-styles_wild_sync__1LdBP .common-styles_sync_content__8knYx ul span {
  margin: 32px 0 0;
  display: flex;
  align-items: center;
}

.common-styles_wild_sync__1LdBP .common-styles_sync_content__8knYx ul span img {
  margin-right: 24px;
}

/*  */

.common-styles_research_content__1hJbM {
  width: 100%;
  max-width: 820px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 120px;
}

.common-styles_contained_image__IJ9NM {
  object-fit: contain !important;
}

.common-styles_research_content__1hJbM .common-styles_research_container__200os {
  width: 100%;
  max-width: 404px;
}

.common-styles_research_content__1hJbM .common-styles_research_container__200os p,
.common-styles_research_content__1hJbM .common-styles_research_bullets__1MMBy li {
  font-size: 16px;
  line-height: 140%;
  margin: 32px 0;
}

.common-styles_research_content__1hJbM .common-styles_research_bullets__1MMBy li {
  list-style-type: disc !important;
  margin: 16px 0px 16px 20px !important;
  font-weight: 400 !important;
}

.common-styles_research_content__1hJbM .common-styles_research_container__200os .common-styles_collab_button__3EiGt {
  width: 290px;
  margin: 32px 0 0;
}

.common-styles_tab_content_for_life_stage_supports__1JQPI {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.common-styles_tab_content_for_life_stage_supports__1JQPI .common-styles_label__3Q-xt {
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 120%;
}

@media screen and (max-width: 992px) {
  .common-styles_common_banner_content___IHhx {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 60px auto 0;
    padding: 0 32px;
  }

  .common-styles_pricing_banner__3nLbV .common-styles_pricing_card__2wlr4 {
    min-width: 340px;
    max-width: 340px;
    padding: 44px 0 24px;
    height: 100%;
  }

  .common-styles_content__4y7TO {
    min-height: 100%;
  }

  .common-styles_list_container__2YwFw > div {
    max-width: 437px !important;
    width: 92%;
    margin: 0 auto;
  }

  .common-styles_pricing_banner__3nLbV {
    margin: 64px auto 0;
  }

  .common-styles_bullets_for_life_support__3b7j_ ul,
  .common-styles_gift_bullets_for_life_support__JanFO {
    padding: 0 24px;
  }

  .common-styles_wild_sync__1LdBP .common-styles_sync_container__yF1qZ {
    padding: 0 24px;
    min-height: 1020px;
  }

  .common-styles_tab_content_for_life_stage_supports__1JQPI {
    max-width: 100% !important;
    padding: 0 16px;
    justify-content: center;
  }

  .common-styles_tab_content_for_life_stage_supports__1JQPI .common-styles_label__3Q-xt {
    font-size: 14px;
    line-height: 140%;
  }

  .common-styles_tab_content_for_life_stage_supports__1JQPI .common-styles_category__1jJlr {
    margin: 8px 8px 0;
  }

  .common-styles_tab_content_for_life_stage_supports__1JQPI .common-styles_black_category__3cpsy {
    margin: 8px 6px 0;
  }

  .common-styles_wild_sync__1LdBP .common-styles_sync_container__yF1qZ h2,
  .common-styles_wild_sync__1LdBP .common-styles_sync_container__yF1qZ h3 {
    text-align: center;
  }

  .common-styles_research_content__1hJbM .common-styles_research_container__200os {
    padding: 0 24px;
    margin: 0 auto;
  }

  .common-styles_research_content__1hJbM .common-styles_research_container__200os h2 {
    text-align: center;
  }

  .common-styles_research_content__1hJbM .common-styles_research_container__200os .common-styles_collab_button__3EiGt {
    width: 290px;
    margin: 32px auto 0;
  }
}

.common-styles_pricing_banner__3nLbV p::selection,
.common-styles_wild_sync__1LdBP h2::selection,
.common-styles_wild_sync__1LdBP h3::selection,
.common-styles_wild_sync__1LdBP li::selection {
  color: #010101 !important;
  background: #ffffff !important;
}

.form_form__3-VZR {
  display: flex;
  position: relative;
  width: 100%;
  height: 44px;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #010101;
  margin-top: 32px;
}

.form_form__3-VZR input,
.form_application_input__Yz8bH,
.form_application_request_input__iClXN {
  width: 100%;
  font-size: 17px;
  line-height: 150%;
  color: #010101;
  border-width: 0px;
  outline: none !important;
  box-shadow: none !important;
  padding: 16px 16px 16px 0px;
  font-family: inherit;
}

.form_form__3-VZR input {
  height: 43px;
}

.form_application_request_input__iClXN {
  color: #000000;
  border-bottom: 1px solid #c9c9c9;
  background: none;
}

.form_application_request_input__iClXN::-webkit-input-placeholder {
  color: #737373 !important;
}

.form_application_request_input__iClXN::placeholder {
  color: #737373 !important;
}

.form_coaches_form_input__3AewU {
  width: 100%;
  font-size: 17px;
  line-height: 150%;
  color: #ffffff;
  border-width: 0px;
  outline: none !important;
  box-shadow: none !important;
  padding: 16px 16px 16px 0px;
  font-family: inherit;
  border-bottom: 1px solid #737373;
  background: none;
}

.form_coaches_form_input__3AewU::-webkit-input-placeholder {
  color: #ffffff !important;
}

.form_coaches_form_input__3AewU::placeholder {
  color: #ffffff !important;
}

.form_application_input__Yz8bH {
  border-bottom: 1px solid #737373;
}

.form_right_absolute_arrow__3w-H5 {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.form_loader__T2VpZ {
  margin-top: 32px;
  border: 6px solid #01010120;
  border-radius: 50%;
  border-top: 6px solid #010101;
  width: 50px;
  height: 50px;
  -webkit-animation: form_spin__3FybT 0.6s linear infinite;
  animation: form_spin__3FybT 0.6s linear infinite;
}

.form_light_loader__L0-uy {
  margin-top: 32px;
  border: 6px solid #ffffff20;
  border-radius: 50%;
  border-top: 6px solid #ffffff;
  width: 50px;
  height: 50px;
  -webkit-animation: form_spin__3FybT 0.6s linear infinite;
  animation: form_spin__3FybT 0.6s linear infinite;
}

@-webkit-keyframes form_spin__3FybT {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes form_spin__3FybT {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.form_error_message__c_3gg,
.form_success_message__3KLuf {
  margin: 16px 0 !important;
  width: 100% !important;
  max-width: 100% !important;
}

.form_error_message__c_3gg {
  font-size: 16px !important;
  color: red !important;
}

.form_success_message__3KLuf {
  font-size: 16px !important;
  color: green !important;
}

.form_custom_file_input_active__2_ttt,
.form_custom_file_input_with_placeholder__3BbIF {
  border-bottom: 1px solid #737373;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-size: 17px;
  line-height: 150%;
  padding: 16px 16px 16px 0px;
  font-family: inherit;
}

.form_custom_file_input_with_placeholder__3BbIF {
  color: rgba(1, 1, 1, 0.4) !important;
}

.form_custom_file_input_active__2_ttt {
  color: #010101 !important;
}

.form_form_row__1eAQQ {
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.form_application_request_select__Hidko,
.form_application_request_select_dark__ekPJ_ {
  width: 100%;
  font-size: 17px;
  line-height: 150%;
  border-width: 0px;
  outline: none !important;
  box-shadow: none !important;
  padding: 16px 16px 16px 0px;
  font-family: inherit;
  border-bottom: 1px solid #c9c9c9;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}

.form_application_request_select__Hidko {
  color: #ffffff;
  background: url(/assets/images/icons/chevron-down-light.svg) no-repeat right;
}

.form_application_request_select_dark__ekPJ_ {
  color: #010101;
  background: url(/assets/images/icons/chevron-down.svg) no-repeat right;
}

.form_application_request_select__Hidko option,
.form_application_request_select_dark__ekPJ_ {
  color: #010101 !important;
}

/* access form card */
.form_access_form_card__ZU9Ng {
  background: #ffffff;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 100;
  margin: 80px auto 0 !important;
}

@media screen and (min-width: 992px) {
  .form_access_form_card__ZU9Ng {
    box-shadow: 0px 25px 47px rgba(0, 0, 0, 0.3);
    margin: -140px auto 0 !important;
    padding: 48px 46px 38px !important;
  }
}

.form_access_form_card__ZU9Ng .form_form_column__wkxxj,
.form_gift_form_card__1Z6Fb .form_form_column__wkxxj {
  padding: 16px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.form_access_form_card__ZU9Ng label,
.form_gift_form_card__1Z6Fb label {
  font-size: 11px;
  line-height: 130%;
  color: #737373;
}

.form_access_form_card__ZU9Ng input,
.form_gift_form_card__1Z6Fb input {
  width: 320px;
  border-bottom: 2px solid #73737370;
}

.form_access_form_card__ZU9Ng input::-webkit-input-placeholder, .form_gift_form_card__1Z6Fb input::-webkit-input-placeholder {
  font-size: 18px;
  line-height: 115%;
  color: #737373;
}

.form_access_form_card__ZU9Ng input::placeholder,
.form_gift_form_card__1Z6Fb input::placeholder {
  font-size: 18px;
  line-height: 115%;
  color: #737373;
}

