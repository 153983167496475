.reports_loading_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    background: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 100%;
}

.pdf_downloading_container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    background: rgba(255, 255, 255, 1);
    height: 100%;
    width: 100%;

}

.custom_loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #010101;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 0.5s linear infinite;
}

.custom_placeholder_loader {
    border: 6px solid #cdc8c8;
    border-top: 6px solid #010101;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 0.5s linear infinite;
}

.screen_custom_loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #010101;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    animation: spin 0.5s linear infinite;
}

.login_card_loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #010101;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    animation: spin 0.5s linear infinite;
}

.custom_small_loader {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #010101;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 0.5s linear infinite;
}

.custom_small_downloading_loader {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #010101;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 0.5s linear infinite;
}

.custom_search_loader {
    border: 3px solid #cecece;
    border-top: 3px solid #010101;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 16px;
    top: 6px;
    z-index: 100;
    margin-top: 0.3rem;
    animation: spin 0.5s linear infinite;
}

.custom_button_loader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #010101;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}