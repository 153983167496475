/* Black Button */
.black,
.black:hover {
  border: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: transparent;
  text-align: center;
  color: #ffffff !important;
  background-color: #000000;
  border-radius: 60px;
  height: 56px;
  letter-spacing: 2px;
  width: 100%;
  box-shadow: none !important;
  text-transform: uppercase;
  cursor: pointer !important;
  text-decoration: none;
  filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important;
}

/* White Button */
.white,
.white:hover {
  border: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  background-color: transparent;
  text-align: center;
  color: #010101 !important;
  background-color: #ffffff;
  border-radius: 24px;
  letter-spacing: 2px;
  height: 46px;
  width: 100%;
  box-shadow: none;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important;
}

.white p,
.black p {
  line-height: 120% !important;
  font-size: 14px !important;
  font-family: "EuclidSquare";
  cursor: pointer;
}

.without_showdow {
  filter: none !important;
}

.without_showdow p {
  text-transform: uppercase !important;
}

.black p::selection {
  background: #ffffff !important;
  color: #010101 !important;
}
