.form {
  display: flex;
  position: relative;
  width: 100%;
  height: 44px;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #010101;
  margin-top: 32px;
}

.form input,
.application_input,
.application_request_input {
  width: 100%;
  font-size: 17px;
  line-height: 150%;
  color: #010101;
  border-width: 0px;
  outline: none !important;
  box-shadow: none !important;
  padding: 16px 16px 16px 0px;
  font-family: inherit;
}

.form input {
  height: 43px;
}

.application_request_input {
  color: #000000;
  border-bottom: 1px solid #c9c9c9;
  background: none;
}

.application_request_input::placeholder {
  color: #737373 !important;
}

.coaches_form_input {
  width: 100%;
  font-size: 17px;
  line-height: 150%;
  color: #ffffff;
  border-width: 0px;
  outline: none !important;
  box-shadow: none !important;
  padding: 16px 16px 16px 0px;
  font-family: inherit;
  border-bottom: 1px solid #737373;
  background: none;
}

.coaches_form_input::placeholder {
  color: #ffffff !important;
}

.application_input {
  border-bottom: 1px solid #737373;
}

.right_absolute_arrow {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.loader {
  margin-top: 32px;
  border: 6px solid #01010120;
  border-radius: 50%;
  border-top: 6px solid #010101;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 0.6s linear infinite;
  animation: spin 0.6s linear infinite;
}

.light_loader {
  margin-top: 32px;
  border: 6px solid #ffffff20;
  border-radius: 50%;
  border-top: 6px solid #ffffff;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 0.6s linear infinite;
  animation: spin 0.6s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.error_message,
.success_message {
  margin: 16px 0 !important;
  width: 100% !important;
  max-width: 100% !important;
}

.error_message {
  font-size: 16px !important;
  color: red !important;
}

.success_message {
  font-size: 16px !important;
  color: green !important;
}

.custom_file_input_active,
.custom_file_input_with_placeholder {
  border-bottom: 1px solid #737373;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-size: 17px;
  line-height: 150%;
  padding: 16px 16px 16px 0px;
  font-family: inherit;
}

.custom_file_input_with_placeholder {
  color: rgba(1, 1, 1, 0.4) !important;
}

.custom_file_input_active {
  color: #010101 !important;
}

.form_row {
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.application_request_select,
.application_request_select_dark {
  width: 100%;
  font-size: 17px;
  line-height: 150%;
  border-width: 0px;
  outline: none !important;
  box-shadow: none !important;
  padding: 16px 16px 16px 0px;
  font-family: inherit;
  border-bottom: 1px solid #c9c9c9;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}

.application_request_select {
  color: #ffffff;
  background: url(/assets/images/icons/chevron-down-light.svg) no-repeat right;
}

.application_request_select_dark {
  color: #010101;
  background: url(/assets/images/icons/chevron-down.svg) no-repeat right;
}

.application_request_select option,
.application_request_select_dark {
  color: #010101 !important;
}

/* access form card */
.access_form_card {
  background: #ffffff;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 100;
  margin: 80px auto 0 !important;
}

@media screen and (min-width: 992px) {
  .access_form_card {
    box-shadow: 0px 25px 47px rgba(0, 0, 0, 0.3);
    margin: -140px auto 0 !important;
    padding: 48px 46px 38px !important;
  }
}

.access_form_card .form_column,
.gift_form_card .form_column {
  padding: 16px 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}

.access_form_card label,
.gift_form_card label {
  font-size: 11px;
  line-height: 130%;
  color: #737373;
}

.access_form_card input,
.gift_form_card input {
  width: 320px;
  border-bottom: 2px solid #73737370;
}

.access_form_card input::placeholder,
.gift_form_card input::placeholder {
  font-size: 18px;
  line-height: 115%;
  color: #737373;
}
