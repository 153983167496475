/* Tabs */

@media screen and (min-width: 992px) {
  .mobile_stages_white_trapezoid_top_bottom {
    border-right: 26.5vw solid transparent;
    border-top: 56px solid #000;
    position: absolute;
    top: -44px;
    left: 0;
    z-index: 2;
  }
}

.news_border_top {
  border-right: 100vw solid transparent;
  border-top: 80px solid #f7f7f7;
  position: absolute;
  transform: rotateY(180deg);
  top: 0;
  right: 0;
  z-index: 1;
}

.lightgray_border_top {
  border-right: 100vw solid transparent;
  border-top: 80px solid #fff;
  position: absolute;
  transform: rotateY(180deg);
  top: 0;
  right: 0;
  z-index: 1;
}

.lightgray_testimonial_border_top {
  border-right: 100vw solid transparent;
  border-top: 80px solid #ffffff;
  position: absolute;
  transform: rotateY(180deg);
  top: -1px;
  right: 0;
  z-index: 1;
}

.lightgray_coachsignup_border_top {
  border-left: 100vw solid transparent;
  border-top: 80px solid #000;
  position: absolute;
  transform: rotateY(180deg);
  top: -1px;
  right: 0;
  z-index: 1;
}

.lightgray_border_bottom {
  border-right: 100vw solid transparent;
  border-bottom: 80px solid #fff;
  position: absolute;
  transform: rotateY(180deg);
  z-index: 1;
  bottom: -1px;
  right: 0;
}

.support_border_top {
  border-right: 100vw solid transparent;
  border-top: 80px solid #ffffff;
  position: absolute;
  top: -1px;
  right: 0;
  transform: rotateY(180deg);
  z-index: 1;
}

.support_border_bottom {
  border-right: 100vw solid transparent;
  border-bottom: 80px solid #ffffff;
  position: absolute;
  transform: rotateY(180deg);
  z-index: 1;
  bottom: 0;
  right: 0;
}

.black_border_top_team {
  border-left: 100vw solid transparent;
  border-bottom: 80px solid #000;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}

.black_border_bottom {
  border-right: 100vw solid transparent;
  border-bottom: 80px solid #000;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
}

.black_border_top {
  border-left: 100vw solid transparent;
  border-top: 80px solid #ffffff;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
}

.black_white_border_top {
  border-left: 100vw solid transparent;
  border-bottom: 80px solid #ffffff;
  position: absolute;
  z-index: 1;
  bottom: 0px;
  left: 0;
}

.white_tiled {
  width: 30px;
  height: 30px;
  transform: matrix(0.74, 0.75, -0.75, 0.74, 0, 0);
  background: #ffffff;
  position: absolute;
  z-index: 1;
  bottom: -10px;
}

.tiled_top_on_image {
  position: absolute;
  top: 0px;
  right: -8px;
  border-right: 213px solid transparent !important;
  border-top: 8px solid #ffffff;
  transform: translate(0px, 0px);
  z-index: 1;
}

.tiled_bottom_on_image {
  position: absolute;
  bottom: 0px;
  left: -8px;
  border-left: 213px solid transparent !important;
  border-bottom: 8px solid #ffffff;
  transform: translate(0px, -4px);
  z-index: 1;
}

.tiled_top {
  position: absolute;
  top: -20px;
  right: 6px;
  background: transparent;
  border-left: 213px solid transparent !important;
  border-bottom: 20px solid #ffffff;
  z-index: 1;
}

.tiled_bottom {
  position: absolute;
  bottom: -20px;
  right: 6px;
  background: transparent;
  border-right: 213px solid transparent !important;
  border-bottom: 20px solid #ffffff;
  z-index: 1;
}

.transparent_top {
  position: absolute;
  top: -24px;
  right: 0px;
  border-left: 390px solid transparent;
  border-bottom: 24px solid #ffffff;
  z-index: 1;
}

.transparent_bottom {
  position: absolute;
  bottom: -24px;
  right: 0px;
  border-right: 390px solid transparent;
  border-top: 24px solid #ffffff;
  z-index: 1;
}

@media screen and (max-width: 922px) {
  .transparent_top {
    top: -23px;
    border-left: 340px solid transparent;
  }

  .transparent_bottom {
    border-right: 340px solid transparent;
    bottom: -23px;
  }
}

/* Paralellogram cards tiled shapes */

.paralellogram_card_border_top {
  border-right: 213px solid transparent;
  border-bottom: 16px solid #ffffff;
  position: absolute;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  transform: rotateY(180deg);
  top: -16px;
  right: 0;
  z-index: 1;
}

.paralellogram_card_border_bottom {
  border-left: 213px solid transparent;
  border-top: 16px solid #ffffff;
  position: absolute;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  transform: rotateY(180deg);
  bottom: -16px;
  right: 0;
  z-index: 1;
}

.paralellogram_card_border_top_on_image {
  border-left: 213px solid transparent;
  border-top: 16px solid #ffffff;
  position: absolute;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  transform: rotateY(180deg);
  top: 0;
  right: 0;
  z-index: 1;
}

.paralellogram_card_border_top_from_label_container {
  border-right: 197px solid transparent;
  border-bottom: 16px solid #ffffff;
  position: absolute;
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  transform: rotateY(180deg);
  top: -16px;
  right: 7.5px;
  z-index: 1;
}
